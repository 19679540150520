import React, { useState } from 'react'

import { Col, Layout, Row, Spin } from 'antd'
import { connect } from "react-redux"
import ym from 'react-yandex-metrika'

import { CardItem } from '../../components'

import { cartProductsAction } from '../../redux/actions'

import './Cards.scss'

const Cards = ({ fetchCartProducts, items, offset=1, span=24, offsetMd=0, spanMd=24, offsetLg=2, spanLg=20 }) => {

    const [numItems, setNumItems] = useState(15)
    const [loading, setLoading] = useState(false)

    const masonryOptions = {
        transitionDuration: 1000,
        // columntWidth: 200,
        gutter: 10,
        // percentPosition: true
    }

    const onMoreBtnClick = () => {
        setLoading(true)
        setTimeout(() => {
            setNumItems(numItems+15)
            setLoading(false)
        }, 2000)
    }
    
    const onCartClick = (id) => {
        let cartProducts = localStorage.getItem('products')
        let cartProduct = null

        if(cartProducts) {
            let cartProductItems = JSON.parse(cartProducts)
            cartProduct = cartProductItems.filter((item) => item.id === id)
            if (cartProduct.length !== 0) {
                cartProductItems.forEach(element => {
                    if (element.id === id) element.count += 1
                })
            } else {
                cartProductItems.push({
                    'id': id,
                    'count': 1
                })
            }
            localStorage.setItem('products', JSON.stringify(cartProductItems))
        } else {
            let products = []
            products.push({'id': id, 'count': 1})
            localStorage.setItem('products', JSON.stringify(products))
        }

        ym('reachGoal','dob-v-korzinu')
        
        fetchCartProducts()
    }

    const MoreButton = (
        <button className="show-more_btn" onClick={onMoreBtnClick}>Показать еще</button>
    )

    return (
        <div>
            {items ?
                <React.Fragment>
                    <Row type="flex" justify="space-around" className="goods">
                        {items.slice(0, numItems).map((item, index) => (
                            <CardItem
                                key={index}
                                id={item.id} 
                                title={item.name} 
                                img={item.image}
                                rating={Number(Math.random()+4).toFixed(1)}
                                delivery='от 30 мин'
                                description={item.comment}
                                weight={item.weight}
                                price={item.price}
                                type='new'
                                filter='vegan'
                                onCartClick={() => onCartClick(item.id)}
                            />
                        ))}
                    </Row>
                    { items.length > numItems ? 
                        <Layout>
                            <Row>
                                <Col xs={{span: 24, offset: 0}} xl={{span: span, offset: 0}}>
                                    <Spin spinning={loading} delay={500}>
                                        {MoreButton}
                                    </Spin>
                                </Col>
                            </Row>
                        </Layout>
                    : null }
                </React.Fragment>
            : null}
        </div>
)}

export default connect(null,cartProductsAction)(Cards)