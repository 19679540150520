import { 
	BANNER_GET_START, BANNER_GET_SUCCESS, BANNER_GET_ERROR
} from '../actions/actionTypes'

const initialState = {
	loading: false,
	banners: [],
}

export default (state = initialState, action) => {
	switch (action.type) {
		case BANNER_GET_START:
			return {
				...state,
				loading: true
			}
		case BANNER_GET_SUCCESS:
			return {
				...state,
				banners: action.banners,
				loading: false
			}
		case BANNER_GET_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			}
		default:
			return state
	}
}