import { testApi } from '../../utils/api'
import { TEST_GET_ITEMS_START, TEST_GET_ITEMS_SUCCESS, TEST_GET_ITEMS_ERROR } from './actionTypes'

const actions = {
    getTestStart: () => ({
        type: TEST_GET_ITEMS_START
    }),
    getTest: items => ({
        type: TEST_GET_ITEMS_SUCCESS,
        object: items
    }),
    getTestError: (e) => ({
        type: TEST_GET_ITEMS_ERROR,
        error: e
    }),
    fetchTest: () => (dispatch) => {
        dispatch(actions.getTestStart())
        testApi.getAll().then((data) => {
            dispatch(actions.getTest(data))
        }).catch((e) => {
            dispatch(actions.getTestError(e))
        })
    }
}

export default actions