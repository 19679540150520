import React from 'react';

import ym from 'react-yandex-metrika';

import './Contact.scss';

const Contact = ({ isFooter = false, show = true }) => {
	const phoneClick = () => {
		ym('reachGoal', 'tel123');
	};

	return (
		<div className='contact'>
			<a
				href='tel:79788398888'
				onClick={phoneClick}
				className={isFooter ? 'footer__phone_white' : 'contact__phone'}
			>
				+7 978 839 88 88
			</a>
			<br />
			<a
				href='tel:79788888810'
				onClick={phoneClick}
				className={isFooter ? 'footer__phone_white' : 'contact__phone'}
			>
				+7 978 888 88 10
			</a>
			{/* <br />
			<a
				href='tel:79788888810'
				onClick={phoneClick}
				className={isFooter ? 'footer__phone_white' : 'contact__phone'}
			>
				8 (3652) 888-001
			</a> */}
			{show && (
				<span className={isFooter ? 'contact__call_white' : 'contact__call'}>
					Доставка осуществляется Пн-Вс с 10:00 до 23:00
				</span>
			)}
		</div>
	);
};

export default Contact;
