import React, { useState, useEffect } from "react"
import { connect } from "react-redux"

import { categoriesAction } from "../../redux/actions"
import { Menu as BaseMenu } from "../../components"

const OrderMenu = ({ fetchCategories, categories, setCurrentCategory, visible, onMenuClick, onChangeSearch, searchInput, placement, top, left, right, bottom, width, height }) => {
    const [filtered, setFilteredItems] = useState(categories ? Array.from(categories) : [])


    useEffect(() => {
        if(!categories) {
            fetchCategories()
        } else {
            setFilteredItems(categories)
        }
    }, [categories])
    
    return (
        <BaseMenu
            visible={visible}
            placement={placement}
            top={top}
            left={left}
            right={right}
            bottom={bottom}
            width={width}
            height={height}
            onMenuClick={onMenuClick}
            onMenuItemClick={setCurrentCategory}
            onChangeSearch={onChangeSearch}
            searchInput={searchInput}
            items={filtered}
        />
    )
}

const mapStateToProps = (state) => {
    return {
        categories: state.categories.categories,
        currentCategory: state.categories.currentCategory,
    }
}

export default connect(
    mapStateToProps,
    categoriesAction
)(OrderMenu)