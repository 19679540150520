import React from 'react'
import { Row } from 'antd'

import './Location.scss'

const Location = () => {
    
    return (
        <Row type="flex" className="location">
            <i className="fal fa-map-marker-alt location__icon"></i>
            <div className="location__desc">
                <span className="location__title">Город доставки</span>
                <a href="/" className="location__city">Симферополь</a>
            </div>
        </Row>
    )
}

export default Location