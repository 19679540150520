import React, { useState, useEffect } from 'react';
import { notification } from 'antd';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import {
	ForgetPasswordForm as BaseForgetPasswordForm,
	ChangePassword,
} from '../../components';
import { usersAction } from '../../redux/actions';

const ForgetPassword = ({
	verifyUser,
	verify,
	sendUserSMS,
	sms,
	recoveryAccess,
	recovery,
	recoveryAccessReset,
}) => {
	const [sent, setSent] = useState(false);
	const [confirmBySMS, setConfirmBySMS] = useState(false);
	const [smsCodeGet, setSmsCodeGet] = useState('');
	const [smsCode, setSmsCode] = useState('');
	const [smsCodeError, setSmsCodeError] = useState('');
	const [userPhone, setUserPhone] = useState('');
	const [userId, setUserId] = useState(null);
	const [userPassword, setUserPassword] = useState('');
	const [userConfirmPassword, setUserConfirmPassword] = useState('');
	const [codeWay, setCodeWay] = useState('call');

	useEffect(() => {
		if (verify) {
			if (verify._id) {
				sendUserSMS(userPhone, codeWay);
				setConfirmBySMS(true);
				setUserId(verify._id);
			} else {
				showWarningNotification(userPhone);
			}
		}
	}, [codeWay, sendUserSMS, userPhone, verify]);

	useEffect(() => {
		if (sms) {
			if (!sms.error) {
				setSmsCodeGet(sms.code);
			}
		}
	}, [sms]);

	useEffect(() => {
		clearState();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sent]);

	useEffect(() => {
		if (recovery) {
			if (recovery._id) {
				showSuccessNotification();
				setTimeout(() => {
					setSent(true);
				}, 2000);
			}
		}
	}, [recovery]);

	const clearState = () => {
		setSent(false);
		setConfirmBySMS('');
		setSmsCodeGet('');
		setSmsCode('');
		setSmsCodeError('');
		setUserPhone('');
		setUserPassword('');
		recoveryAccessReset();
	};

	const onUserPhoneChange = (e) => setUserPhone(e.target.value);

	const onSMSCodeChange = (e) => setSmsCode(e.target.value);

	const onUserPasswordChange = (e) => setUserPassword(e.target.value);

	const onUserCodeWayChange = (e) => setCodeWay(e.target.value);

	const onUserConfirmPasswordChange = (e) =>
		setUserConfirmPassword(e.target.value);

	const showSuccessNotification = () => {
		notification.info({
			message: 'Восстановление пароля',
			description: 'Пароль успешно изменен!',
			placement: 'topRight',
		});
	};

	const showWarningNotification = (phone) => {
		notification.warning({
			message: 'Восстановление пароля',
			description: `Пользователь с номером ${phone} не найден`,
			placement: 'topRight',
		});
	};

	const submitCodeForm = () => {
		if (smsCode === smsCodeGet) {
			const userData = {
				id: userId,
				password: userPassword,
			};

			recoveryAccess(userData);
		} else {
			setSmsCodeError('Неверный код');
		}
	};

	const onSubmitForm = (values) => {
		let phone = userPhone.replace('+', '').replace(/-/g, '').replace(/ /g, '');

		const userData = {
			phone: phone.indexOf('8') === 0 ? `7${phone.substring(1)}` : phone,
		};

		verifyUser(userData);
	};

	return sent ? (
		<Redirect to='/signin' />
	) : confirmBySMS ? (
		<ChangePassword
			phone={userPhone}
			sms={smsCode}
			smsGet={smsCodeGet}
			smsError={smsCodeError}
			userPassword={userPassword}
			userConfirmPassword={userConfirmPassword}
			userId={userId}
			onUserPasswordChange={onUserPasswordChange}
			onUserConfirmPasswordChange={onUserConfirmPasswordChange}
			onSMSCodeChange={onSMSCodeChange}
			submitCodeForm={submitCodeForm}
			codeWay={codeWay}
		/>
	) : (
		<BaseForgetPasswordForm
			userPhone={userPhone}
			onUserPhoneChange={onUserPhoneChange}
			onUserConfirmPasswordChange={onUserConfirmPasswordChange}
			submitForm={onSubmitForm}
			onUserCodeWayChange={onUserCodeWayChange}
			// clicked={clicked}
		/>
	);
};

const mapStateToProps = (state) => {
	return {
		verify: state.users.verify,
		sms: state.users.sms,
		recovery: state.users.recovery,
	};
};

export default connect(mapStateToProps, usersAction)(ForgetPassword);
