export const CATEGORIES_GET_ITEMS_START = 'CATEGORIES:GET_ITEMS_START'
export const CATEGORIES_GET_ITEMS_SUCCESS = 'CATEGORIES:GET_ITEMS_SUCCESS'
export const CATEGORIES_GET_ITEMS_ERROR = 'CATEGORIES:GET_ITEMS_ERROR'
export const CATEGORIES_SET_CURRENT = 'CATEGORIES:SET_CURRENT'

export const PRODUCTS_GET_ITEMS_START = 'PRODUCTS:GET_ITEMS_START'
export const PRODUCTS_GET_ITEMS_SUCCESS = 'PRODUCTS:GET_ITEMS_SUCCESS'
export const PRODUCTS_GET_ITEMS_ERROR = 'PRODUCTS:GET_ITEMS_ERROR'

export const PRODUCTS_CART_GET_ITEMS_START = 'PRODUCTS:CART_GET_ITEMS_START'
export const PRODUCTS_CART_GET_ITEMS_SUCCESS = 'PRODUCTS:CART_GET_ITEMS_SUCCESS'
export const PRODUCTS_CART_GET_ITEMS_ERROR = 'PRODUCTS:CART_GET_ITEMS_ERROR'

export const CART_SET_VISIBLE = 'CART:SET_VISIBLE'

export const CART_ORDER_START = 'CART:ORDER_START'
export const CART_ORDER_SUCCESS = 'CART:ORDER_SUCCESS'
export const CART_ORDER_ERROR = 'CART:ORDER_ERROR'

export const USER_REGISTER_START = 'USER:REGISTER_START'
export const USER_REGISTER_SUCCESS = 'USER:REGISTER_SUCCESS'
export const USER_REGISTER_ERROR = 'USER:REGISTER_ERROR'
export const USER_REGISTER_END = 'USER:REGISTER_END'
export const USER_REGISTER_RESET = 'USER:REGISTER_RESET'

export const USER_VERIFY_START = 'USER:VERIFY_START'
export const USER_VERIFY_SUCCESS = 'USER:VERIFY_SUCCESS'
export const USER_VERIFY_ERROR = 'USER:VERIFY_ERROR'

export const USER_SMS_START = 'USER:SMS_START'
export const USER_SMS_SUCCESS = 'USER:SMS_SUCCESS'
export const USER_SMS_ERROR = 'USER:SMS_ERROR'

export const USER_LOGIN_START = 'USER:LOGIN_START'
export const USER_LOGIN_SUCCESS = 'USER:LOGIN_SUCCESS'
export const USER_LOGIN_ERROR = 'USER:LOGIN_ERROR'

export const USER_UPDATE_START = 'USER:UPDATE_START'
export const USER_UPDATE_SUCCESS = 'USER:UPDATE_SUCCESS'
export const USER_UPDATE_ERROR = 'USER:UPDATE_ERROR'

export const USER_CURRENT_START = 'USER:CURRENT_START'
export const USER_CURRENT_SUCCESS = 'USER:CURRENT_SUCCESS'
export const USER_CURRENT_ERROR = 'USER:CURRENT_ERROR'
export const USER_CURRENT_RESET = 'USER:CURRENT_RESET'

export const USER_ORDERS_START = 'USER:ORDERS_START'
export const USER_ORDERS_SUCCESS = 'USER:ORDERS_SUCCESS'
export const USER_ORDERS_ERROR = 'USER:ORDERS_ERROR'

export const USER_ACCESS_RECOVERY_START = 'USER:ACCESS_RECOVERY_START'
export const USER_ACCESS_RECOVERY_SUCCESS = 'USER:ACCESS_RECOVERY_SUCCESS'
export const USER_ACCESS_RECOVERY_ERROR = 'USER:ACCESS_RECOVERY_ERROR' 
export const USER_ACCESS_RECOVERY_RESET = 'USER:ACCESS_RECOVERY_RESET'

export const REVIEW_ADD_START = 'REVIEW:ADD_START'
export const REVIEW_ADD_SUCCESS = 'REVIEW:ADD_SUCCESS'
export const REVIEW_ADD_ERROR = 'REVIEW:ADD_ERROR'
export const REVIEW_ADD_RESET = 'REVIEW:ADD_RESET'

export const REVIEW_GET_START = 'REVIEW:GET_START'
export const REVIEW_GET_SUCCESS = 'REVIEW:GET_SUCCESS'
export const REVIEW_GET_ERROR = 'REVIEW:GET_ERROR'

export const BANNER_GET_START = 'BANNER:GET_START'
export const BANNER_GET_SUCCESS = 'BANNER:GET_SUCCESS'
export const BANNER_GET_ERROR = 'BANNER:GET_ERROR'

export const TEST_GET_ITEMS_START = 'TEST:GET_ITEMS_START'
export const TEST_GET_ITEMS_SUCCESS = 'TEST:GET_ITEMS_SUCCESS'
export const TEST_GET_ITEMS_ERROR = 'TEST:GET_ITEMS_ERROR'

