import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { notification } from 'antd'

import { reviewsAction } from "../redux/actions"
import { Reviews as BaseReviews } from "../components"

const Reviews = ({ fetchReviews, reviews, addReview, review, reviewReset }) => {
    const [filtered, setFilteredItems] = useState(Array.from(reviews))
    const [userName, setUserName] = useState('')
    const [sending, setSending] = useState(false)
    const [userPhone, setUserPhone] = useState('')
    const [userEmail, setUserEmail] = useState('')
    const [reviewText, setReviewText] = useState('')

    useEffect(() => {
        if(!reviews.length) {
            fetchReviews()
        } else {
            setFilteredItems(reviews)
        }
    }, [reviews])

    useEffect(() => {
        if(review) {
            notification.info({
                message: 'Добавление отзыва',
                description: 'Ваш отзыв добавлен. Спасибо, что пользуетесь нашей продукцией!',
                placement: 'topRight'
            })
            setTimeout(() => {
                clearState()
            }, 1000)
            setSending(false)
        } else setSending(false)
    }, [review])

    const clearState = () => {
        setUserName('')
        setUserPhone('')
        setUserEmail('')
        setReviewText('')
        reviewReset()
    }

    const onUserNameChange = e => setUserName(e.target.value)
    const onUserPhoneChange = e => setUserPhone(e.target.value)
    const onUserEmailChange = e => setUserEmail(e.target.value)
    const onReviewTextChange = e => setReviewText(e.target.value)

    const onSendButtonClick = () => {
        setSending(true)
        let phone = userPhone.replace('+','').replace(/-/g, '').replace(/ /g, '')

        if (userName === '' || reviewText === '' || phone === '') {
            notification.error({
                message: 'Добавление отзыва',
                description: 'Ваш отзыв не добавлен. Заполните все поля!',
                placement: 'topRight'
            })
        setSending(false)
        } else {
            addReview({
                'userPhone': phone.indexOf('8') === 0 ? `7${phone.substring(1)}` : phone,
                'userName': userName,
                'userEmail': userEmail,
                'reviewText': reviewText
            })
    
            setUserName('')
            setUserPhone('')
            setUserEmail('')
            setReviewText('')
        }
    }

    return (
        <BaseReviews
            items={filtered}
            userName = {userName}
            userPhone = {userPhone}
            userEmail = {userEmail}
            reviewText = {reviewText}
            sending = {sending}
            onSendButtonClick = {onSendButtonClick}
            onUserNameChange = {onUserNameChange}
            onUserPhoneChange = {onUserPhoneChange}
            onUserEmailChange = {onUserEmailChange}
            onReviewTextChange = {onReviewTextChange}
        />
    )
}

export default connect(
    ({ reviews, review }) => reviews,
    reviewsAction
)(Reviews)