import { usersApi } from "../../utils/api"

import {
    USER_REGISTER_START,
    USER_REGISTER_SUCCESS,
    USER_REGISTER_ERROR,
    USER_REGISTER_END,
    USER_REGISTER_RESET,
    USER_VERIFY_START,
    USER_VERIFY_SUCCESS,
    USER_VERIFY_ERROR,
    USER_SMS_START,
    USER_SMS_SUCCESS,
    USER_SMS_ERROR,
    USER_UPDATE_START,
    USER_UPDATE_SUCCESS,
    USER_UPDATE_ERROR,
    USER_LOGIN_START,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_ERROR,
    USER_CURRENT_START,
    USER_CURRENT_SUCCESS,
    USER_CURRENT_ERROR,
    USER_CURRENT_RESET,
    USER_ORDERS_START,
    USER_ORDERS_SUCCESS,
    USER_ORDERS_ERROR,
    USER_ACCESS_RECOVERY_START,
    USER_ACCESS_RECOVERY_SUCCESS,
    USER_ACCESS_RECOVERY_ERROR,
    USER_ACCESS_RECOVERY_RESET,
} from "./actionTypes"

const actions = {
    // register user
    registerUserStart: () => ({
        type: USER_REGISTER_START,
        error: false,
        registerEnd: false,
    }),
    getUser: (user) => ({
        type: USER_REGISTER_SUCCESS,
        user: user,
        error: false,
    }),
    registerUserError: (e) => ({
        type: USER_REGISTER_ERROR,
        error: true,
        registerEnd: false,
    }),
    registerUserEnd: () => ({
        type: USER_REGISTER_END,
        error: false,
        registerEnd: true,
    }),
    registerUserReset: () => ({
        type: USER_REGISTER_RESET,
    }),
    registerUser: (data) => (dispatch) => {
        dispatch(actions.registerUserStart())
        usersApi
            .registerUserApi(data)
            .then((res) => {
                dispatch(actions.getUser(res))
            })
            .then(() => {
                dispatch(actions.registerUserEnd())
            })
            .catch((e) => {
                dispatch(actions.registerUserError(e))
            })
    },
    // verify user
    verifyUserStart: () => ({
        type: USER_VERIFY_START,
        error: false,
    }),
    verifyUserEnd: (verify) => ({
        type: USER_VERIFY_SUCCESS,
        verify: verify,
        error: false,
    }),
    verifyUserError: (e) => ({
        type: USER_VERIFY_ERROR,
        error: true,
    }),
    verifyUser: (data) => (dispatch) => {
        dispatch(actions.verifyUserStart())
        usersApi
            .verifyUserApi(data)
            .then((res) => {
                dispatch(actions.verifyUserEnd(res))
            })
            .catch((e) => {
                dispatch(actions.verifyUserError(e))
            })
    },
    // sign in
    loginUserStart: () => ({
        type: USER_LOGIN_START,
        error: false,
    }),
    loginUserEnd: (login) => ({
        type: USER_LOGIN_SUCCESS,
        login: login,
        error: false,
    }),
    loginUserError: (e) => ({
        type: USER_LOGIN_ERROR,
        error: true,
    }),
    loginUser: (data) => (dispatch) => {
        dispatch(actions.loginUserStart())
        usersApi
            .loginUserApi(data)
            .then((res) => {
                if (res.error) dispatch(actions.loginUserError(res.error))
                dispatch(actions.loginUserEnd(res.user))
            })
            .catch((e) => {
                dispatch(actions.loginUserError(e))
            })
    },
    // get Current user
    currentUserStart: () => ({
        type: USER_CURRENT_START,
        error: false,
    }),
    currentUserEnd: (currentUser) => ({
        type: USER_CURRENT_SUCCESS,
        currentUser: currentUser,
        error: false,
    }),
    currentUserError: (e) => ({
        type: USER_CURRENT_ERROR,
        error: true,
    }),
    currentUserReset: () => ({
        type: USER_CURRENT_RESET,
    }),
    getCurrentUser: () => (dispatch) => {
        dispatch(actions.currentUserStart())
        usersApi
            .getUserApi()
            .then((res) => {
                dispatch(actions.currentUserEnd(res.user.user))
            })
            .catch((e) => {
                dispatch(actions.currentUserError(e))
            })
    },
    // access recovery
    recoveryAccessStart: () => ({
        type: USER_ACCESS_RECOVERY_START,
        error: false,
    }),
    recoveryAccessEnd: (recovery) => ({
        type: USER_ACCESS_RECOVERY_SUCCESS,
        recovery: recovery,
        error: false,
    }),
    recoveryAccessError: (e) => ({
        type: USER_ACCESS_RECOVERY_ERROR,
        error: true,
    }),
    recoveryAccessReset: () => ({
        type: USER_ACCESS_RECOVERY_RESET,
    }),
    recoveryAccess: (data) => (dispatch) => {
        dispatch(actions.recoveryAccessStart())
        usersApi
            .recoveryAccessApi(data)
            .then((res) => {
                dispatch(actions.recoveryAccessEnd(res.user))
            })
            .catch((e) => {
                dispatch(actions.recoveryAccessError(e))
            })
    },
    // get user orders
    userOrdersStart: () => ({
        type: USER_ORDERS_START,
        error: false,
    }),
    userOrdersEnd: (userOrders) => ({
        type: USER_ORDERS_SUCCESS,
        userOrders: userOrders,
        error: false,
    }),
    userOrdersError: (e) => ({
        type: USER_ORDERS_ERROR,
        error: true,
    }),
    getUserOrders: () => (dispatch) => {
        dispatch(actions.userOrdersStart())
        usersApi
            .getUserOrdersApi()
            .then((res) => {
                dispatch(actions.userOrdersEnd(res))
            })
            .catch((e) => {
                dispatch(actions.userOrdersError(e))
            })
    },
    // send sms to user
    sendUserSMSStart: () => ({
        type: USER_SMS_START,
        error: false,
    }),
    sendUserSMSEnd: (sms) => ({
        type: USER_SMS_SUCCESS,
        sms: sms,
        error: false,
    }),
    sendUserSMSError: (e) => ({
        type: USER_SMS_ERROR,
        error: true,
    }),
    sendUserSMS: (data, codeWay) => (dispatch) => {
        dispatch(actions.sendUserSMSStart())
        usersApi
            .sendSMSApi(data, codeWay)
            .then((res) => {
                dispatch(actions.sendUserSMSEnd(res))
            })
            .catch((e) => {
                dispatch(actions.sendUserSMSError(e))
            })
    },
    // update user
    updateUserStart: () => ({
        type: USER_UPDATE_START,
    }),
    updateUserEnd: (currentUser) => ({
        type: USER_UPDATE_SUCCESS,
        currentUser: currentUser,
    }),
    updateUserError: (e) => ({
        type: USER_UPDATE_ERROR,
    }),
    updateUser: (data) => (dispatch) => {
        dispatch(actions.updateUserStart())
        usersApi
            .changeUserApi(data)
            .then((res) => {
                dispatch(actions.updateUserEnd(res.user))
            })
            .catch((e) => {
                dispatch(actions.updateUserError(e))
            })
    },
}

export default actions
