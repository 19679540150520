import React, { useState, useEffect } from 'react';
import { Form } from 'antd';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { LoginForm as BaseSignIn } from '../../components';
import { usersAction } from '../../redux/actions';

const SignIn = ({ loginUser, verifyUser, login, verify, error }) => {
	const [userPhone, setUserPhone] = useState('');
	const [userPassword, setUserPassword] = useState('');
	const [isAuth, setIsAuth] = useState(false);
	const [loading, setLoading] = useState(false);

	const [loginError, setLoginError] = useState(false);
	const [messagePhoneError, setMessagePhoneError] = useState('');
	const [messagePasswordError, setMessagePasswordError] = useState('');

	const [form] = Form.useForm();

	useEffect(() => {
		if (verify) {
			if (verify._id) {
				let phone = userPhone
					.replace('+', '')
					.replace(/-/g, '')
					.replace(/ /g, '');

				loginUser({
					phone: phone.indexOf('8') === 0 ? `7${phone.substring(1)}` : phone,
					password: userPassword,
				});
			} else {
				setLoginError(true);
				setLoading(false);
				setMessagePhoneError(
					`Пользователь с номером телефона ${userPhone} не найден`
				);
			}
		}
	}, [verify]);

	useEffect(() => {
		if (login) {
			if (login._id) {
				localStorage.setItem('user', login._id);

				setTimeout(() => {
					setIsAuth(true);
					clearState();
					setLoading(false);
				}, 2000);
			} else {
				setIsAuth(false);
				setLoading(false);
			}
		} else {
			setIsAuth(false);
			setLoading(false);
		}
	}, [login]);

	useEffect(() => {
		if (error) {
			if (verify) {
				if (verify._id) {
					setLoginError(true);
					setLoading(false);
					setMessagePasswordError(`Неверный пароль`);
				}
			}
		}
	}, [error]);

	const clearState = () => {
		setUserPhone('');
		setUserPassword('');
	};

	const onUserPhoneChange = (e) => setUserPhone(e.target.value);

	const onUserPasswordChange = (e) => setUserPassword(e.target.value);

	const onSubmitForm = (values) => {
		setLoading(true);
		setLoginError(false);
		setMessagePasswordError('');
		setMessagePhoneError('');

		let phone = userPhone.replace('+', '').replace(/-/g, '').replace(/ /g, '');

		const userData = {
			phone: phone.indexOf('8') === 0 ? `7${phone.substring(1)}` : phone,
			password: userPassword,
		};
		verifyUser(userData);
	};

	return isAuth ? (
		<Redirect to='/account' />
	) : (
		<BaseSignIn
			userPhone={userPhone}
			userPassword={userPassword}
			loginError={loginError}
			messagePhoneError={messagePhoneError}
			loading={loading}
			messagePasswordError={messagePasswordError}
			onUserPhoneChange={onUserPhoneChange}
			onUserPasswordChange={onUserPasswordChange}
			signForm={form}
			submitForm={onSubmitForm}
		/>
	);
};

const mapStateToProps = (state) => {
	return {
		login: state.users.login,
		error: state.users.error,
		verify: state.users.verify,
	};
};

export default connect(mapStateToProps, usersAction)(SignIn);
