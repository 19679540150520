import moment from 'moment';
import { axios } from '../../core';

import { usersApi } from './index';

const getProductById = async (_id) => {
	let prod = await axios({
		method: 'get',
		url: `/item/${_id}`,
	});

	if (prod) {
		return prod.data.item;
	}
};

const setItems = async (cartProducts) => {
	let prod = null;

	return Promise.all(
		cartProducts.map(async (item) => {
			prod = await getProductById(item.id);
			if (prod.id) {
				let price = parseFloat(prod.price);
				return {
					id: item.id,
					name: prod.name,
					count: item.count,
					price: price,
					image: prod.image,
					sum: item.count * price,
				};
			}
		})
	);
};

const getAll = async () => {
	const localStor = localStorage.getItem('products');

	if (localStor === null)
		return {
			data: [],
			total: 0,
			summa: 0,
		};

	const cartProducts = JSON.parse(localStor);

	const prod = await setItems(cartProducts).then((prods) => {
		return prods;
	});

	if (prod) {
		let sumCount = prod.reduce((sumTotal, item) => sumTotal + item.count, 0);
		let sumSumma = prod.reduce((sumSum, item) => sumSum + item.sum, 0);

		return {
			data: prod,
			total: sumCount,
			summa: sumSumma,
		};
	}
};

const setOrder = async (dataOrder) => {
	// console.log('Order', dataOrder

	try {
		let comment = dataOrder.comment;
		let timeDel = dataOrder.orderTime.split(':');

		if (timeDel.length === 1) {
			timeDel = moment().format('HH:mm').split(':');
			comment += ` | Время доставки: ${moment().format('HH:mm')}`;
		} else {
			comment += ` | Время доставки: ${dataOrder.orderTime}`;
		}

		let items = [];

		await dataOrder.items.map((item) => {
			items.push({
				id: item.id,
				count: item.count,
			});
		});

		let phone = dataOrder.customerPhone
			.replace('+', '')
			.replace(/-/g, '')
			.replace(/ /g, '');

		const userData = {
			name: dataOrder.customerName,
			phone: phone.indexOf('8') === 0 ? `7${phone.substring(1)}` : phone,
			email: '',
			address: {
				city: dataOrder.city,
				street: dataOrder.street,
				house: dataOrder.house,
				apartment: dataOrder.appartment,
				porch: dataOrder.porch,
				floor: dataOrder.floor,
			},
			password: '1234567',
			male: true,
		};

		let userId = localStorage.getItem('user');

		if (!userId) userId = await usersApi.getUserOnOrderCart(userData);

		let sumCash = null,
			sumCard = null,
			sumCreditCard = null;

		if (dataOrder.paymentType === 'cash') {
			// У нас есть сдача с - dataOrder.change
			// у нас есть оплата наличными dataOrder.summa и оплата бонусами dataOrder.sumCard
			// если dataOrder.change указана и больше суммы заказа dataOrder.summa, тогда sumCash у нас равна dataOrder.change
			//

			if (dataOrder.sumCard != 0) {
				if (dataOrder.summa < dataOrder.sumCard) {
					sumCard = dataOrder.summa;
				} else {
					sumCard = dataOrder.sumCard;
				}
			}

			if (dataOrder.change > dataOrder.summa) {
				sumCash = dataOrder.change;
			} else if (!dataOrder.change || dataOrder.change === 0) {
				sumCash = dataOrder.summa - dataOrder.sumCard;
			}
		}
		if (dataOrder.paymentType === 'card_on_delivery') {
			if (dataOrder.summa < dataOrder.sumCard) sumCreditCard = dataOrder.summa;
			else sumCreditCard = dataOrder.summa - dataOrder.sumCard;

			if (dataOrder.sumCard != 0) {
				sumCard = dataOrder.sumCard;
			}
		}
		if (dataOrder.paymentType === 'bonus') sumCard = dataOrder.summa;

		const dataSend = {
			firstName: dataOrder.customerName,
			lastName: '',
			phone: phone.indexOf('8') === 0 ? `7${phone.substring(1)}` : phone,
			address: {
				city: dataOrder.city,
				street: dataOrder.street,
				house: dataOrder.house,
				apartment: dataOrder.appartment,
				porch: dataOrder.porch,
				floor: dataOrder.floor,
			},
			seats: parseInt(dataOrder.foodDevice),
			payType: dataOrder.paymentType,
			dateDelivery: [parseInt(timeDel[0]), parseInt(timeDel[1])],
			userId: userId,
			bonusSum: dataOrder.bonusSum,
			sum: parseInt(dataOrder.change),
			comment: comment,
			items: items,
			sumCash: parseInt(sumCash),
			sumCard: parseInt(sumCard),
			sumCreditCard: parseInt(sumCreditCard),
			fromSite: true,
		};

		// console.log(dataSend)

		let token = localStorage.getItem('@@token');

		const result = await axios({
			method: 'post',
			url: '/order',
			data: dataSend,
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});

		if (result.data) return result.data;
		// }
	} catch (e) {
		console.log('Error', e);
		await localStorage.removeItem('user');
		window.location.reload();
		return e;
	}
};

export default {
	getProductById,
	getAll,
	setOrder,
};
