import React from 'react'
import config from '../../utils/api/config'

import './AppLinks.scss'

import appStore from '../../assets/img/appstore.png'
import googlePlay from '../../assets/img/google.png'

const AppLinks = () => {
    
    return (
        <div className="app">
            <div className="app__icons">
                <a href="https://apps.apple.com/ru/app/slipetsgroup-delivery/id1506195793" className="app__link" target="_blank"><img src={appStore} alt="" className="app__icon" /></a>
                {/* <a href="https://play.google.com/store/apps/details?id=com.demmel.slipets" className="app__link" target="_blank"><img src={googlePlay} alt="" className="app__icon" /></a> */}
                <a href="https://sg.tab-is.com/garrys.apk" className="app__link" target="_blank"><img src={googlePlay} alt="" className="app__icon" /></a>
            </div>
            <span className="app__text">Скачать <a href={`${config.api.server}/garrys.apk`} className="app__link">APK-файл</a> для Android</span>
        </div>
    )
}

export default AppLinks