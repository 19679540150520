module.exports = {
	secret: 'mysecretcodeword',
	crypto: {
		hash: {
			length: 128,
			iterations: 12000,
		},
	},
	admin: {
		login: 'admin',
		password: 'fb652510e0bc477e8266',
	},
	api: {
		// server: 'https://sg.bnet24.com'
		// server: 'http://sg.bnet24.com'
		server: 'https://sg.tab-is.com',
	},
};
