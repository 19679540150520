import React from 'react';
import config from '../../utils/api/config'

import { Carousel, Col, Layout, Row } from 'antd';
// import classNames from 'classnames';

import { SliderItem } from '../../components'

import './Sliders.scss';

const Sliders = ({ items }) => (
    
    <Layout className="slider">
        <Row>
            <Col span={24}>
                <Carousel autoplay autoplaySpeed={4000}>
                    {items ? items.map((item, index) => {
                            let $links = ""
                            let innerLink = true
                            if (item.url.indexOf("http://") === 0 || item.url.indexOf("https://") === 0) {
                                $links = item.url
                                innerLink = false
                            } else if (item.url === "") {
                                $links = ""
                            } else {
                                $links = `${config.api.server}/${item.url}`
                            }
                            return (<SliderItem link={$links} title={item.title} slide={`${config.api.server}/${item.picture}`} key={index} innerLink={innerLink}/>)
                        }
                    ):null}
                </Carousel>
            </Col>
        </Row>
    </Layout>
);

export default Sliders;