import React, { useState } from 'react'
import { Layout, Row, Col, Input, Form, Spin } from 'antd'
import moment from 'moment'

import './Reviews.scss'

import ReviewItem from './ReviewItem'

const { Content } = Layout
const { TextArea } = Input

const Reviews = ({
        items, userName, userPhone, userEmail, reviewText, sending,
        onSendButtonClick, onUserNameChange, onUserPhoneChange, onUserEmailChange, onReviewTextChange
    }) => {

    const [numItems, setNumItems] = useState(15)
    const [loading, setLoading] = useState(false)

    const onMoreBtnClick = () => {
        setLoading(true)
        setTimeout(() => {
            setNumItems(numItems+15)
            setLoading(false)
        }, 2000)
    }

    const MoreButton = (
        <button className="show-more_btn" onClick={onMoreBtnClick}>Показать еще</button>
    )

    return (
        <Content className="reviews">
            <Row className="reviews__item">
                <Col lg={{span: 18, offset: 3}}>
                    <h1 className="reviews__header">Оставить отзыв</h1>
                </Col>
            </Row>
            <Row className="reviews__item">
                <Col lg={{span: 18, offset: 3}}>
                    <Form name="reviewForm" className="reviews__card">
                        <div className="order-form">
                            <div className="order-pay">
                                <span className="sign__label">Отзыв</span><span className="order-form__require">*</span>
                                <Form.Item name="comment" rules={[{ required: true, message: 'Оставьте отзыв' }]}>
                                    <TextArea className="order-form__textarea" rows={8} placeholder="Оставьте свой отзыв о нас и нашей продукции" value={reviewText} onChange={onReviewTextChange}/>
                                </Form.Item>
                            </div>
                            <Row type="flex" align="middle" justify="space-between">
                                <div className="sign__item reviews__input">
                                    <span className="sign__label">Имя</span><span className="order-form__require">*</span>
                                    <Form.Item name="userName" rules={[{ required: true, message: 'Укажите свое имя' }]}>
                                        <input type="text" className="sign__input" placeholder="Иванов Иван" value={userName} onChange={onUserNameChange}/>
                                    </Form.Item>
                                </div>
                                <div className="sign__item reviews__input">
                                    <span className="sign__label">E-mail</span>
                                    <Form.Item>
                                        <input type="email" className="sign__input" placeholder="user@somedomain.com" value={userEmail} onChange={onUserEmailChange}/>
                                    </Form.Item>
                                </div>
                                <div className="sign__item reviews__input">
                                    <span className="sign__label">Телефон</span><span className="order-form__require">*</span>
                                    <Form.Item name="userPhone" rules={[
                                        { required: true, message: 'Укажите свой номер телефона' },
                                        () => ({
                                            validator(_, value) {
                                                value = value.replace('+','').replace(/-/g, '').replace(/_/g,'').replace(/ /g,'').trim()
                                                if (value.length == 11) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject('Некорректный номер телефона!');
                                            },
                                          })
                                    ]}>
                                        <input type="tel" className="sign__input" placeholder="+7 123 456-78-90" value={userPhone} onChange={onUserPhoneChange}/>
                                    </Form.Item>
                                </div>
                            </Row>
                            <div className="sign__action reviews__button">
                                <Spin spinning={sending} delay={500} className="reviews__spin">
                                    <button type="submit" className='cart_btn sign__button' onClick={onSendButtonClick}>Отправить отзыв</button>
                                </Spin>
                            </div>
                        </div>
                    </Form>
                    { items.length > 0 ? 
                    <div className="reviews__card reviews__card_padding">
                        <h3 className="reviews__title">Всего отзывов: {items.length}</h3>
                        <div className="reviews__items">
                            {items.map((item, index) => (
                                <ReviewItem
                                    key = {index}
                                    userName = {item.name}
                                    reviewText = {item.text}
                                    reviewDate = {moment(item.created_at).format("DD.MM.YYYY HH:mm")}
                                />
                            ))}
                        </div>
                        { items.length > numItems ? 
                            <Layout className="reviews__more-btn">
                                <Row>
                                    <Col span={24}>
                                        <Spin spinning={loading} delay={500}>
                                            {MoreButton}
                                        </Spin>
                                    </Col>
                                </Row>
                            </Layout>
                        : null }
                    </div> : null }
                </Col>
            </Row> 
        </Content>
    )
}

export default Reviews