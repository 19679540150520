import { combineReducers } from 'redux'
import products from './products'
import cartProducts from './cartProducts'
import categories from './categories'
import users from './users'
import reviews from './reviews'
import news from './news'
import test from './test'

export default combineReducers({
    products,
    categories,
    cartProducts,
    users,
    reviews,
    news,
    test
});