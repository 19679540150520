import React, { useState } from "react"
import { Row, Col } from "antd"

import "../LoginForm/LoginForm.scss"

const ChangePassword = (props) => {
    const [smsError, setSmsError] = useState("")
    const [passwordError, setPasswordError] = useState("")

    const submitForm = () => {
        if (props.sms !== props.smsGet) {
            setSmsError("Неверный код")
        } else {
            setSmsError("")
            if (props.userPassword === "") {
                setPasswordError("Пароль не может быть пустым")
            } else {
                if (props.userPassword !== props.userConfirmPassword) {
                    setPasswordError("Пароли не совпадают")
                } else props.submitCodeForm()
            }
        }
    }

    return (
        <Row>
            <Col span={24}>
                <Row type="flex" align="bottom" justify="center">
                    <div className="sign">
                        <h3 className="sign__link_active">Восстановление доступа</h3>
                        {props.codeWay === "call" ? (
                            <p className="sign__text">
                                Ожидайте входящий вызов на указанный телефон, код действителен в течении 3 трех минут
                            </p>
                        ) : (
                            <p className="sign__text">
                                Вам отправлено сообщение на указанный телефон, код действителен в течении 3 трех минут
                            </p>
                        )}
                        <div className="sign__form sign__form_sms">
                            <div className="sign__item">
                                <span className="sign__label">Номер телефона</span>
                                <input
                                    type="text"
                                    className="sign__input sign__input_phone"
                                    disabled
                                    value={props.phone}
                                />
                            </div>
                            <div className="sign__item">
                                {props.codeWay === "call" ? (
                                    <span className="sign__label">
                                        Введите последние 4 цифры номера входящего звонка
                                    </span>
                                ) : (
                                    <span className="sign__label">Код подтверждения из SMS</span>
                                )}
                                <input
                                    type="text"
                                    maxLength={props.codeWay === "call" ? 4 : 6}
                                    className="sign__input sign__input_sms"
                                    onChange={props.onSMSCodeChange}
                                    value={props.sms}
                                />
                                {smsError !== "" ? <span className="sign__error">{smsError}</span> : null}
                            </div>
                            <div className="sign__item">
                                <span className="sign__label">Новый пароль</span>
                                <input
                                    type="password"
                                    className="sign__input"
                                    onChange={props.onUserPasswordChange}
                                    value={props.userPassword}
                                />
                                {passwordError !== "" ? <span className="sign__error">{passwordError}</span> : null}
                            </div>
                            <div className="sign__item">
                                <span className="sign__label">Новый пароль</span>
                                <input
                                    type="password"
                                    className="sign__input"
                                    onChange={props.onUserConfirmPasswordChange}
                                    value={props.userConfirmPassword}
                                />
                            </div>
                            <div className="sign__action">
                                <button
                                    type="submit"
                                    className="cart_btn sign__button"
                                    disabled={props.sms === "" ? true : false}
                                    onClick={submitForm}
                                >
                                    Отправить
                                </button>
                            </div>
                        </div>
                    </div>
                </Row>
            </Col>
        </Row>
    )
}

export default ChangePassword
