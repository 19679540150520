import React, { useState, useEffect } from "react"
import { Form, notification } from "antd"
import { connect } from "react-redux"
import { Redirect } from "react-router-dom"

import { SignUpForm as BaseSignUp, ConfirmSMS } from "../../components"
import { usersAction } from "../../redux/actions"

const SignUp = ({
    registerUser,
    verifyUser,
    user,
    verify,
    sendUserSMS,
    sms,
    registerEnd,
    registerUserReset,
    error,
}) => {
    const [sent, setSent] = useState(false)
    // const [currentUser, setCurrentUser] = useState(user)
    const [confirmBySMS, setConfirmBySMS] = useState(false)
    const [smsCodeGet, setSmsCodeGet] = useState("")
    const [smsCode, setSmsCode] = useState("")
    const [smsCodeError, setSmsCodeError] = useState("")
    const [userName, setUserName] = useState("")
    const [userPhone, setUserPhone] = useState("")
    const [userEmail, setUserEmail] = useState("")
    const [userPassword, setUserPassword] = useState("")
    const [userSex, setUserSex] = useState("male")
    const [codeWay, setCodeWay] = useState("call")

    const [form] = Form.useForm()

    useEffect(() => {
        if (verify) {
            if (verify._id) {
                showWarningNotification(verify.message)
            } else {
                sendUserSMS(userPhone, codeWay)
                setConfirmBySMS(true)
            }
        }
    }, [codeWay, sendUserSMS, userPhone, verify])

    useEffect(() => {
        if (sms) {
            if (!sms.error) {
                setSmsCodeGet(sms.code)
            }
        }
    }, [sms])

    useEffect(() => {
        clearState()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sent])

    useEffect(() => {
        if (registerEnd) {
            showSuccessNotification()
            setTimeout(() => {
                setSent(true)
            }, 2000)
        }
    }, [registerEnd])

    const clearState = () => {
        setSent(false)
        setConfirmBySMS("")
        setSmsCodeGet("")
        setSmsCode("")
        setSmsCodeError("")
        setUserName("")
        setUserPhone("")
        setUserEmail("")
        setUserPassword("")
        setUserSex("male")
        setCodeWay("call")
        registerUserReset()
    }

    const onUserNameChange = (e) => setUserName(e.target.value)

    const onUserPhoneChange = (e) => setUserPhone(e.target.value)

    const onUserEmailChange = (e) => setUserEmail(e.target.value)

    const onUserPasswordChange = (e) => setUserPassword(e.target.value)

    const onUserSexChange = (e) => setUserSex(e.target.value)

    // const onUserCodeWayChange = (e) => console.log("e.target.value", e.target.value)
    const onUserCodeWayChange = (e) => setCodeWay(e.target.value)

    const onSMSCodeChange = (e) => setSmsCode(e.target.value)

    const showSuccessNotification = () => {
        notification.info({
            message: "Регистрация",
            description: "Вы успешно зарегистрировались в системе. Спасибо, что выбрали нас!",
            placement: "topRight",
        })
    }

    const showWarningNotification = (message) => {
        notification.warning({
            message: "Регистрация",
            description: `Ошибка регистрации: ${message}`,
            placement: "topRight",
        })
    }

    const submitCodeForm = () => {
        if (smsCode === smsCodeGet) {
            let phone = userPhone.replace("+", "").replace("-", "").replace(" ", "")

            const userData = {
                name: userName,
                phone: phone.indexOf("8") === 0 ? `7${phone.substring(1)}` : phone,
                email: userEmail,
                password: userPassword,
                male: userSex === "male",
            }

            registerUser(userData)
        } else {
            setSmsCodeError("Неверный код")
        }
    }

    const onSubmitForm = (values) => {
        let phone = userPhone.replace("+", "").replace(/-/g, "").replace(/ /g, "")

        const userData = {
            name: userName,
            phone: phone.indexOf("8") === 0 ? `7${phone.substring(1)}` : phone,
            email: userEmail,
            password: userPassword,
            male: userSex === "male",
        }

        verifyUser(userData)
    }

    return sent ? (
        <Redirect to="/signin" />
    ) : confirmBySMS ? (
        <ConfirmSMS
            phone={userPhone}
            sms={smsCode}
            smsGet={smsCodeGet}
            smsError={smsCodeError}
            onSMSCodeChange={onSMSCodeChange}
            submitCodeForm={submitCodeForm}
            codeWay={codeWay}
        />
    ) : (
        <BaseSignUp
            userName={userName}
            userPhone={userPhone}
            userEmail={userEmail}
            userPassword={userPassword}
            userSex={userSex}
            onUserNameChange={onUserNameChange}
            onUserPhoneChange={onUserPhoneChange}
            onUserEmailChange={onUserEmailChange}
            onUserPasswordChange={onUserPasswordChange}
            onUserSexChange={onUserSexChange}
            onUserCodeWayChange={onUserCodeWayChange}
            signForm={form}
            submitForm={onSubmitForm}
            // clicked={clicked}
        />
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.users.user,
        verify: state.users.verify,
        sms: state.users.sms,
        error: state.users.error,
        registerEnd: state.users.registerEnd,
    }
}

export default connect(mapStateToProps, usersAction)(SignUp)
