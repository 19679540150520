import { 
	USER_REGISTER_START, USER_REGISTER_SUCCESS, USER_REGISTER_ERROR, USER_REGISTER_END, USER_REGISTER_RESET,
	USER_VERIFY_START, USER_VERIFY_SUCCESS, USER_VERIFY_ERROR,
	USER_SMS_START, USER_SMS_SUCCESS, USER_SMS_ERROR, USER_UPDATE_START, USER_UPDATE_SUCCESS, USER_UPDATE_ERROR,
	USER_LOGIN_START, USER_LOGIN_SUCCESS, USER_LOGIN_ERROR,
	USER_CURRENT_START, USER_CURRENT_SUCCESS, USER_CURRENT_ERROR, USER_CURRENT_RESET,
	USER_ORDERS_START, USER_ORDERS_SUCCESS, USER_ORDERS_ERROR,
	USER_ACCESS_RECOVERY_START, USER_ACCESS_RECOVERY_SUCCESS, USER_ACCESS_RECOVERY_ERROR, USER_ACCESS_RECOVERY_RESET

} from '../actions/actionTypes'

const initialState = {
	user: null,
	error: false, 
	verify: null,
	login: null,
	currentUser: null,
	userOrders: null,
	sms: null,
	registerEnd: false,
	recovery: null
}

export default (state = initialState, action) => {
	switch (action.type) {
		case USER_REGISTER_START:
			return {
				...state,
				error: false,
				registerEnd: false
			}
		case USER_REGISTER_SUCCESS:
			return {
				...state,
				user: action.user,
				error: false,
				registerEnd: false
			}
		case USER_REGISTER_ERROR:
			return {
				...state,
				error: true,
				registerEnd: false
			}
		case USER_REGISTER_END:
			return {
				...state,
				error: false,
				registerEnd: true
			}
		case USER_REGISTER_RESET:
			return {
				...initialState
			}
		case USER_VERIFY_START:
			return {
				...state,
				error: false
			}
		case USER_VERIFY_SUCCESS:
			return {
				...state,
				verify: action.verify,
				error: false
			}
		case USER_VERIFY_ERROR:
			return {
				...state,
				error: true
			}
		case USER_LOGIN_START:
			return {
				...state,
				error: false
			}
		case USER_LOGIN_SUCCESS:
			return {
				...state,
				login: action.login,
				error: false
			}
		case USER_LOGIN_ERROR:
			return {
				...state,
				error: true
			}
		case USER_ACCESS_RECOVERY_START:
			return {
				...state,
				error: false
			}
		case USER_ACCESS_RECOVERY_SUCCESS:
			return {
				...state,
				recovery: action.recovery,
				error: false
			}
		case USER_ACCESS_RECOVERY_RESET:
				return {
					...initialState
				}
		case USER_ACCESS_RECOVERY_ERROR:
			return {
				...state,
				error: true
			}
		case USER_CURRENT_START:
			return {
				...state,
				error: false
			}
		case USER_CURRENT_SUCCESS:
			return {
				...state,
				currentUser: action.currentUser,
				error: false
			}
		case USER_CURRENT_RESET:
			return {
				...initialState
			}
		case USER_CURRENT_ERROR:
			return {
				...state,
				error: true
			}
		case USER_ORDERS_START:
			return {
				...state,
				error: false
			}
		case USER_ORDERS_SUCCESS:
			return {
				...state,
				userOrders: action.userOrders,
				error: false
			}
		case USER_ORDERS_ERROR:
			return {
				...state,
				error: true
			}
		case USER_SMS_START:
			return {
				...state,
				error: false
			}
		case USER_SMS_SUCCESS:
			return {
				...state,
				sms: action.sms,
				error: false
			}
		case USER_SMS_ERROR:
			return {
				...state,
				error: true
			}
		case USER_UPDATE_START:
			return {
				...state,
			}
		case USER_UPDATE_SUCCESS:
			return {
				...state,
				currentUser: action.currentUser,
			}
		case USER_UPDATE_ERROR:
			return {
				...state,
			}
		default:
			return state
	}
}