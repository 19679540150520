import React from 'react'
import { Form, Row, Col, Spin } from 'antd'
import InputMask from "react-input-mask"
import { NavLink } from 'react-router-dom'

import './LoginForm.scss'

const LoginForm = (props) => {
    
    return (
        <Row>
            <Col span={24}>
                <Row type="flex" align="bottom" justify="center">
                    <div className="sign sign_short">
                        <div className="sign__menu">
                            <NavLink to="/signin" className="sign__link sign__link_active">Вход</NavLink>
                            <NavLink to="/signup" className="sign__link">Регистрация</NavLink>
                        </div>
                        <Form name="signInForm">
                            <div className="sign__form">
                                <div className="sign__item">
                                    <span className="sign__label">Телефон</span>
                                    <Form.Item name="phone">
                                        <InputMask mask="+9 999 999-99-99" placeholder="+7 123 456-78-90" className="sign__input" onChange={props.onUserPhoneChange}/>
                                        {/* <input type="tel" className="sign__input" placeholder="+7 123 456-78-90" onChange={props.onUserPhoneChange}/> */}
                                        {props.messagePhoneError !== '' && <span className="sign__error">{props.messagePhoneError}</span>}
                                    </Form.Item>
                                </div>
                                <div className="sign__item">
                                    <span className="sign__label">Пароль</span>
                                    <Form.Item name="password" type="password">
                                        <input type="password" className="sign__input" placeholder="*********"  onChange={props.onUserPasswordChange}/>
                                        {props.messagePasswordError !== '' && <span className="sign__error">{props.messagePasswordError}</span>}
                                    </Form.Item>
                                </div>
                                <div className="sign__action">
                                    <Spin spinning={props.loading} delay={500}>
                                        <button type="submit" className='cart_btn sign__button' onClick={props.submitForm}>Войти</button>
                                    </Spin>
                                </div>
                                <Row type="flex" align="middle" justify="center">
                                    <NavLink to="/forget" className="sign__forget">Забыли пароль?</NavLink>
                                </Row>
                            </div>
                        </Form>
                    </div>
                </Row>
            </Col>
        </Row>
    )
}

export default LoginForm