import { PRODUCTS_GET_ITEMS_START, PRODUCTS_GET_ITEMS_SUCCESS, PRODUCTS_GET_ITEMS_ERROR } from '../actions/actionTypes'

const initialState = {
	loading: false,
	products: null
}

export default (state = initialState, action) => {
	switch (action.type) {
		case PRODUCTS_GET_ITEMS_START:
			return {
				...state,
				loading: true
			}
		case PRODUCTS_GET_ITEMS_SUCCESS:
			return {
				...state,
				products: action.products,
				loading: false
			}
		case PRODUCTS_GET_ITEMS_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			}
		default:
			return state
	}
}