import React, { useState } from 'react'

import { Col, Layout, Row } from 'antd';

const MenuCategory = ({ index, item, onClickMenuItem }) => {

    const [subMenuVisible, setSubMenuVisible] = useState(false)

    const renderSubMenu = (subMenu) => {
        return subMenu.map((item, index) => {
            return (<li key={index} className="menu-item__subitem menu-item__subitem_mobile" onClick={() => onClickMenuItem(item.id, false)}>{item.name}</li>)
        })
    }
    
    return (
        <div key={index} className="menu-item menu-item_mobile">
            <Row type="flex">
                <span className="menu-item__title menu-item__title_mobile" onClick={() => onClickMenuItem(item.id, true)}>{item.name}</span>
                {item.items.length !== 0 && <span className="menu-item__more" onClick={() => setSubMenuVisible(!subMenuVisible)}>{!subMenuVisible ? <i className="fal fa-plus-circle"/>:<i className="fal fa-minus-circle"/>}</span>}
            </Row>
            <ul className="menu-item__subitems">
                {
                    item.items.length !== 0 && subMenuVisible ? renderSubMenu(item.items) : null
                }
            </ul>
        </div> 
    )
}

export default MenuCategory