import React from 'react'
import { Menu } from 'antd'

import './MainNav.scss'
import { NavLink } from 'react-router-dom'

const { Item } = Menu

const MainNav = (props) => {

    const onMenuClick = () => {
        props.onMenuClick(true)
    }
    
    return (
        <Menu mode="horizontal" className="menu">
            <Item key="menu" className="item">
                <span onClick={onMenuClick} className="item__link" rel="noopener noreferrer">
                    <i className="fal fa-burger-soda"></i>
                    Меню
                </span>
            </Item>
            <Item key="delivery" className="item">
                <NavLink to="/delivery" className="item__link" rel="noopener noreferrer">
                    Доставка
                </NavLink>
            </Item>
            {/* <Item key="response" className="item">
                <NavLink to="/comments" className="item__link" rel="noopener noreferrer">
                    Отзывы
                </NavLink>
            </Item> */}
            {/* <Item key="bonus" className="item">
                <NavLink to="/bonuses" className="item__link" rel="noopener noreferrer">
                    Бонусы
                </NavLink>
            </Item> */}
            <Item key="about" className="item">
                <NavLink to="/about" className="item__link" rel="noopener noreferrer">
                    О нас
                </NavLink>
            </Item>
            {/* <Item key="special" className="item">
                <NavLink to="/faq" className="item__link" rel="noopener noreferrer">
                    ЧАВО
                </NavLink>
            </Item> */}
        </Menu>
    )
}

export default MainNav