import React from "react"
import { Layout } from 'antd'

import { Footer } from "../../components"

import { Reviews } from '../../containers'

import "./Comment.scss"

const Comment = () => {
	return (
		<React.Fragment>
			<Reviews />
			<Layout>
				<Footer />
			</Layout>
		</React.Fragment>
	)
}

export default Comment
