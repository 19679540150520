import React from 'react'
import { Link } from 'react-router-dom';

const SliderItem = ({ link, title, slide, innerLink = false}) => {
    return (
        <div className="slider-item">
            {link ==='' ? <img src={slide} alt={title} className="slider-item__img" /> : innerLink ? <Link to={link}><img src={slide} alt={title} className="slider-item__img" /></Link>:<a href={link} target="_blank"><img src={slide} alt={title} className="slider-item__img" /></a>}
        </div>
    );
}

export default SliderItem;