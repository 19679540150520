import React, { useState, useEffect } from "react"
import { connect } from "react-redux"

import { newsAction } from "../redux/actions"
import { Sliders as BaseSliders } from "../components"

const Sliders = ({ fetchBanners, banners }) => {
    const [filtered, setFilteredItems] = useState(Array.from(banners))
    const [tries, setTries] = useState(0)

    useEffect(() => {
        if(!banners.length) {
            if (tries < 4) {
                setTries(tries+1)
                fetchBanners()
            }
        } else {
            setFilteredItems(banners)
        }
    }, [banners])

    return (
        <BaseSliders
            items={filtered}
        />
    )
}

const mapStateToProps = (state) => {
    return {
        banners: state.news.banners,
        loading: state.news.loading
    }
}

export default connect(
    mapStateToProps,
    newsAction
)(Sliders)