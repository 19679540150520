import React from "react"
import { Layout } from 'antd'

import { Footer } from "../../components"

import "./News.scss"

const News = () => {
	return (
		<React.Fragment>
			<Layout>
				<Footer />
			</Layout>
		</React.Fragment>
	)
}

export default News
