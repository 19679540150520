import React from 'react'

import './Social.scss'
import { Row } from 'antd'

const Social = () => {
    
    return (
        <div className="social">
            <h4 className="social__text">Мы в соцсетях:</h4>
            <Row type="flex" align="middle" justify="space-between" className="social__icon">
                <a href="https://instagram.com/sg.dostavka" target="_blank" className="social__link"><i className="fab fa-instagram"></i></a>
                <a href="https://vk.com/sg.dostavka" target="_blank" className="social__link"><i className="fab fa-vk"></i></a>
                <a href="https://www.facebook.com/Slipets-Group-%D0%94%D0%BE%D1%81%D1%82%D0%B0%D0%B2%D0%BA%D0%B0-114882527042992/" target="_blank" className="social__link"><i className="fab fa-facebook-f"></i></a>
            </Row>
        </div>
    )
}

export default Social