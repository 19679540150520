import React from "react"
import { Layout } from 'antd'

import { Footer, Delivery } from "../../components"

const DeliveryPage = () => {
	return (
		<React.Fragment>
			<Delivery />
			<Layout>
				<Footer />
			</Layout>
		</React.Fragment>
	)
}

export default DeliveryPage
