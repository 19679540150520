import { 
	PRODUCTS_CART_GET_ITEMS_START, PRODUCTS_CART_GET_ITEMS_SUCCESS, PRODUCTS_CART_GET_ITEMS_ERROR,
	CART_ORDER_START, CART_ORDER_SUCCESS, CART_ORDER_ERROR, CART_SET_VISIBLE
} from '../actions/actionTypes'

const initialState = {
	loading: false,
	cartProducts: null,
	order: null,
	total: 0,
	summa: 0,
	visible: false
}

export default (state = initialState, action) => {
	switch (action.type) {
		case CART_SET_VISIBLE:
			return {
				...state,
				visible: action.visible
			}
		case PRODUCTS_CART_GET_ITEMS_START:
			return {
				...state,
				loading: true
			}
		case PRODUCTS_CART_GET_ITEMS_SUCCESS:
			return {
				...state,
				cartProducts: action.cartProducts,
				total: action.total,
				summa: action.summa,
				loading: false
			}
		case PRODUCTS_CART_GET_ITEMS_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			}
		case CART_ORDER_START:
			return {
				...state,
				loading: true
			}
		case CART_ORDER_SUCCESS:
			return {
				...state,
				cartProducts: action.cartProducts,
				order: action.order,
				total: action.total,
				summa: action.summa,
				loading: false
			}
		case CART_ORDER_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			}
		default:
			return state
	}
}