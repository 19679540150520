import React from 'react'

import { OrderMenu, OrderProducts, OrderCart } from "../../containers"

class Order extends React.Component {

    state = {
        visible: false,
        searchInput: ''
    }

    componentDidUpdate(prevProps) {
        if (prevProps.visible !== this.props.visible) {
          this.setState({
              visible: this.props.visible
          })
        }
    }
    
    onMenuClick = (visibleCart) => {
        this.props.onMenuClick(visibleCart)
        this.setState({
            visible: visibleCart
        })
    }

    onChangeSearch = value => {
        this.setState({
            searchInput: value
        })
    }

    render() {
        return (
            <div>
                <OrderProducts
                    right={this.props.prodRight}
                    left={this.props.prodLeft}
                    top={this.props.prodTop}
                    bottom={this.props.prodBottom}
                    placement={this.props.prodPlacement}
                    width={this.props.prodWidth}
                    height={this.props.prodHeight}
                    visible={this.state.visible}
                    searchInput={this.state.searchInput}
                    onMenuClick={this.onMenuClick}
                />
                <OrderCart 
                    right={this.props.cartRight}
                    left={this.props.cartLeft}
                    top={this.props.cartTop}
                    bottom={this.props.cartBottom}
                    placement={this.props.cartPlacement}
                    width={this.props.cartWidth}
                    height={this.props.cartHeight}
                    visible={this.state.visible} 
                    onMenuClick={this.onMenuClick}
                />
                <OrderMenu
                    right={this.props.menuRight}
                    left={this.props.menuLeft}
                    top={this.props.menuTop}
                    bottom={this.props.menuBottom}
                    placement={this.props.menuPlacement}
                    width={this.props.menuWidth}
                    height={this.props.menuHeight}
                    visible={this.state.visible}
                    searchInput={this.state.searchInput}
                    onChangeSearch={this.onChangeSearch}
                    onMenuClick={this.onMenuClick} 
                />
            </div>
        )
    }
    
}

export default Order