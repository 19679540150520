import React, { useState, useEffect } from "react"
import { connect } from "react-redux"

import { productsAction } from "../redux/actions"
import { Cards as BaseCards } from "../components"

const Cards = ({ fetchProducts, products }) => {
    const [filtered, setFilteredItems] = useState(products ? Array.from(products) : [])

    useEffect(() => {
        if(!products) {
            fetchProducts()
        } else {
            setFilteredItems(products)
        }
    }, [products])

    return (
        <BaseCards
            items={filtered}
        />
    )
}

export default connect(
    ({ products }) => products,
    productsAction
)(Cards)