import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from 'redux'
import ym from 'react-yandex-metrika'

import { Row, Divider, Empty, Form, notification } from 'antd'

import { cartProductsAction, categoriesAction } from "../../redux/actions"
import { Cart as BaseCart, CartItem } from "../../components"

const OrderCart = ({ fetchCartProducts, currentUser, setOrder, order, setCartVisible, cartProducts, total, summa, visible, placement, top, left, right, bottom, width, height }) => {
    const [filtered, setFilteredItems] = useState(cartProducts ? Array.from(cartProducts) : [])
    const [city] =  useState('Симферополь')
    const [street, setStreet] = useState('')
    const [house, setHouse] = useState('')
    const [appartment, setAppartment] = useState('')
    const [porch, setPorch] = useState('')
    const [floor, setFloor] = useState('')
    const [customerName, setCustomerName] = useState(currentUser ? currentUser.name : '')
    const [customerPhone, setCustomerPhone] = useState(currentUser ? `+${currentUser.phone}` : '')
    const [foodDevice, setFoodDevice] = useState('none')
    const [orderTime, setOrderTime] = useState('')
    const [paymentType, setPaymentType] = useState('cash')
    const [change, setChange] = useState('')
    const [comment, setComment] = useState('')
    const [bonusPaymentDisabled, setBonusPayment] = useState(false)
    const [bonusErrorMessage, setBonusErrorMessage] = useState(null)
    const [userPoints, setUserPoints] = useState(0)
    const [sumCard, setSumCard] = useState(0)

    const [form] = Form.useForm()

    useEffect(() => {
        if(!cartProducts) {
            fetchCartProducts()
        } else {
            setFilteredItems(cartProducts)
        }
    }, [cartProducts])

    useEffect(() => {
        if(currentUser) {
            setCustomerName(currentUser.name)
            setCustomerPhone(`+${currentUser.phone}`)
            setBonusPayment(true)
            setUserPoints(currentUser.points)
        }
    }, [currentUser])

    useEffect(() => {
        if (currentUser) {
            if (currentUser.points < summa && paymentType === "bonus") {
                setBonusErrorMessage('Недостаточно бонусов для оплаты заказа')
            } else setBonusErrorMessage(null)
        } else setBonusErrorMessage(null)
    }, [summa])

    useEffect(() => {
        if(order) {
            if(order.num) {
                showSuccessNotification()
                setTimeout(() => {
                    onClearCartClick()
                    clearForm()
                }, 2000)
            }
        }
       
    }, [order])

    useEffect(() => {
        setCartVisible(visible)
    }, [visible])

    const showSuccessNotification = () => {
        notification.info({
            message: 'Оформление заказа',
            description: 'Заказ оформлен, ожидайте звонка оператора, для подтверждения заказа',
            placement: 'topRight'
        })
    }

    const clearForm = () => {
        setStreet('')
        setHouse('')
        setAppartment('')
        setPorch('')
        setFloor('')
        setCustomerName('')
        setCustomerPhone('')
        setOrderTime('')
        setFoodDevice('none')
        setPaymentType('cash')
        setChange('')
        setComment('')
    }

    const onClearCartClick = () => {
        localStorage.removeItem('products')

        fetchCartProducts()
        setFilteredItems([])
    }

    const setEmptyItems = () => setFilteredItems([])

    const onStreetChange = e => setStreet(e.target.value)

    const onHouseChange = e => setHouse(e.target.value)

    const onAppartmentChange = e => setAppartment(e.target.value)

    const onPorchChange = e => setPorch(e.target.value)

    const onFloorChange = e => setFloor(e.target.value)

    const onCustomerNameChange = e => setCustomerName(e.target.value)

    const onCustomerPhoneChange = e => setCustomerPhone(e.target.value)

    const onFoodDeviceChange = value => setFoodDevice(value)

    const onNowOrderTimeChange = e => setOrderTime(e.target.checked ? 'now' : '')

    const onOrderTimeChange = time => setOrderTime(time.format('HH:mm'))

    const onPaymentTypeChange = e => {
        setPaymentType(e.target.value)

        if(e.target.value === 'bonus') {
            if (currentUser) {
                if (currentUser.points < summa) {
                    setBonusErrorMessage('Недостаточно бонусов для оплаты заказа')
                } else setBonusErrorMessage(null)
            } else setBonusErrorMessage(null)
        } else setBonusErrorMessage(null)
    }

    const onCashChange = e => setChange(e.target.value)

    const onCommentChange = e => setComment(e.target.value)

    const onInputSumCardChange = e => setSumCard(e.target.value)

    const onSumCardChange = value => setSumCard(value)

    const onSubmitForm = (values) => {
        // console.log(values)
        const dataOrder = {
            'items': filtered,
            'summa': summa,
            'city': city,
            'street': street,
            'house': house,
            'appartment': appartment,
            'porch': porch,
            'floor': floor,
            'customerName': customerName,
            'customerPhone': customerPhone,
            'foodDevice': foodDevice === 'none' ? 0 : foodDevice,
            'orderTime': orderTime,
            'paymentType': paymentType,
            'bonusSum': paymentType === 'bonus' ? summa : 0,
            'sumCard': parseInt(sumCard),
            'change': change,
            'site': true,
            'comment': comment
        }

        ym('reachGoal','zakazat-3')
        setOrder(dataOrder)
        
    }

    const renderCartProducts = () => {
        return filtered.map((item, index) => {
            return(
                <CartItem
                    key={index}
                    id={item.id}
                    name={item.name}
                    count={item.count}
                    img={item.image}
                    price={item.price}
                    summa={item.sum}
                    setFilteredItems={setEmptyItems}
                />
            )
        })
    }
    
    return (
        <BaseCart
            visible={visible}
            total={total}
            city={city}
            street={street}
            house={house}
            appartment={appartment}
            porch={porch}
            floor={floor}
            customerName={customerName}
            customerPhone={customerPhone}
            foodDevice={foodDevice}
            orderTime={orderTime}
            paymentType={paymentType}
            change={change}
            comment={comment}
            placement={placement}
            top={top}
            left={left}
            right={right}
            bottom={bottom}
            width={width}
            height={height}
            onStreetChange={onStreetChange}
            onHouseChange={onHouseChange}
            onAppartmentChange={onAppartmentChange}
            onPorchChange={onPorchChange}
            onFloorChange={onFloorChange}
            onCustomerNameChange={onCustomerNameChange}
            onCustomerPhoneChange={onCustomerPhoneChange}
            onFoodDeviceChange={onFoodDeviceChange}
            onNowOrderTimeChange={onNowOrderTimeChange}
            onOrderTimeChange={onOrderTimeChange}
            onPaymentTypeChange={onPaymentTypeChange}
            onCashChange={onCashChange}
            onCommentChange={onCommentChange}
            onSumCardChange={onSumCardChange}
            orderForm={form}
            submitForm={onSubmitForm}
            userPoints={userPoints}
            sumCard={sumCard}
            bonusPaymentDisabled={bonusPaymentDisabled}
            bonusErrorMessage={bonusErrorMessage}
            onInputSumCardChange={onInputSumCardChange}
        >
            <div className="cart">
                <h4 className="cart__title">Ваш заказ</h4>
                <div className="cart__items">
                    {filtered.length !== 0 ? renderCartProducts() : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Ваша корзина пуста" />}
                    {(filtered.length !== 0) &&
                    <React.Fragment>
                        <Divider />
                        <div className="cart__prod-count">{total} блюд(а)</div>
                        <Row className="cart__summary" type="flex" align="middle" justify="space-between">
                            <div className="cart__sum-text">К оплате:</div>
                            <div className="cart__price cart__price_lg">
                                {summa} <i className="fas fa-ruble-sign"></i>
                            </div> 
                        </Row>
                        <button onClick={onClearCartClick} className="cart_btn">Очистить корзину</button>
                    </React.Fragment>}
                </div>
            </div>
        </BaseCart>
    )
}

const mapStateToProps = (state) => {
    return {
        cartProducts: state.cartProducts.cartProducts,
        total: state.cartProducts.total,
        summa: state.cartProducts.summa,
        order: state.cartProducts.order,
        currentUser: state.users.currentUser,
        currentCategory: state.categories.currentCategory,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCartProducts: bindActionCreators(cartProductsAction.fetchCartProducts, dispatch),
		setCurrentCategory: bindActionCreators(categoriesAction.setCurrentCategory, dispatch),
        setOrder: bindActionCreators(cartProductsAction.setOrder, dispatch),
        setCartVisible: bindActionCreators(cartProductsAction.setCartVisible, dispatch),
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(OrderCart)