import { productsApi } from '../../utils/api'

import { PRODUCTS_GET_ITEMS_START, PRODUCTS_GET_ITEMS_SUCCESS, PRODUCTS_GET_ITEMS_ERROR } from './actionTypes'

const actions = {
    getProductsStart: () => ({
        type: PRODUCTS_GET_ITEMS_START
    }),
    getProducts: items => ({
        type: PRODUCTS_GET_ITEMS_SUCCESS,
        products: items
    }),
    getProductsError: (e) => ({
        type: PRODUCTS_GET_ITEMS_ERROR,
        error: e
    }),
    fetchProducts: () => (dispatch) => {
        dispatch(actions.getProductsStart())
        productsApi.getAll().then((data) => {
            dispatch(actions.getProducts(data))
        }).catch((e) => {
            dispatch(actions.getProductsError(e))
        })
    },
    fetchProductsByCategory: (categoryId, mainCategory) => (dispatch) => {
        dispatch(actions.getProductsStart())
        if (mainCategory)
            productsApi.getAllByMainCategoryId(categoryId).then((data) => {
                dispatch(actions.getProducts(data))
            }).catch((e) => {
                dispatch(actions.getProductsError(e))
            })
        else productsApi.getAllByCategoryId(categoryId).then((data) => {
                dispatch(actions.getProducts(data))
            }).catch((e) => {
                dispatch(actions.getProductsError(e))
            })
    }
}

export default actions