import React, { useState, useEffect } from "react"
import { connect } from "react-redux"

import { productsAction } from "../../redux/actions"
import { Products as BaseProducts } from "../../components"

const OrderProducts = ({ fetchProducts, products, fetchProductsByCategory, categoryId, mainCategory, visible, onMenuClick, searchInput, placement, top, left, right, bottom, width, height }) => {
    const [filtered, setFilteredItems] = useState(products ? Array.from(products) : [])

    useEffect(() => {
        if(!products) {
            if (!categoryId) fetchProducts()
            else setFilteredItems([])
        } else {
            setFilteredItems(products)
        }
    }, [products])

    useEffect(() => {
        if(categoryId) {
            fetchProductsByCategory(categoryId, mainCategory)
        } else {
            fetchProducts()
        }
    }, [categoryId])

    useEffect(() => {
        if (searchInput.length > 0) 
            setFilteredItems(
                products.filter(
                    item => item.name.toLowerCase().includes(searchInput.toLowerCase()) === true
                )
            )
    }, [searchInput])
    
    return (
        <BaseProducts
            placement={placement}
            top={top}
            left={left}
            right={right}
            bottom={bottom}
            width={width}
            height={height}
            visible={visible}
            onMenuClick={onMenuClick}
            span={23}
            offset={1}
            items={filtered}
        />
    )
}

export default connect(
    ({ categories, products }) => ({ 
        products: products.products,
        categoryId: categories.currentCategory,
        mainCategory: categories.mainCategory 
    }),
    productsAction
)(OrderProducts)