import { reviewsApi } from '../../utils/api'

import { 
	REVIEW_GET_START, REVIEW_GET_SUCCESS, REVIEW_GET_ERROR,
	REVIEW_ADD_START, REVIEW_ADD_SUCCESS, REVIEW_ADD_ERROR, REVIEW_ADD_RESET
} from '../actions/actionTypes'

const actions = {
    getReviewsStart: () => ({
        type: REVIEW_GET_START
    }),
    getReviews: items => ({
        type: REVIEW_GET_SUCCESS,
        reviews: items
    }),
    getReviewsError: (e) => ({
        type: REVIEW_GET_ERROR,
        error: e
    }),
    fetchReviews: () => (dispatch) => {
        dispatch(actions.getReviewsStart())
        reviewsApi.getAll().then((data) => {
            dispatch(actions.getReviews(data))
        }).catch((e) => {
            dispatch(actions.getReviewsError(e))
        })
    },
    addReviewStart: () => ({
        type: REVIEW_ADD_START
    }),
    addReviewEnd: item => ({
        type: REVIEW_ADD_SUCCESS,
        review: item
    }),
    addReviewError: (e) => ({
        type: REVIEW_ADD_ERROR,
        error: e
    }),
    addReview: (reviewData) => (dispatch) => {
        dispatch(actions.addReviewStart())
        reviewsApi.addReviewApi(reviewData).then((data) => {
            dispatch(actions.addReviewEnd(data))
        }).catch((e) => {
            dispatch(actions.addReviewError(e))
        })
    },
    reviewReset: () => ({
        type: REVIEW_ADD_RESET,
    }),
}

export default actions