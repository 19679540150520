import React from 'react'

import { Col, Layout, Row } from 'antd'

import SmartBanner from 'react-smartbanner'

import '../../../node_modules/react-smartbanner/dist/main.css'

import './AppBanner.scss'

const AppBanner = () => {
    
    return (
        <Layout className="banner">
            <Row>
                <SmartBanner 
                    title={'Доставка вкусной еды'}
                    daysHidden={1}
                    button={'Открыть'}
                    storeText={{ ios: 'В App Store', android: 'В Google Play' }}
                    price={{ ios: 'БЕСПЛАТНО', android: 'БЕСПЛАТНО' }}
                    position={'top'}
                    force={'ios'}
                    url={{ ios: 'https://apps.apple.com/ru/app/slipetsgroup-delivery/id1506195793', android: 'https://play.google.com/store/apps/details?id=com.demmel.slipets' }}
                    appMeta={{ ios: 'apple-itunes-app', android: 'google-play-app' }}
                />
            </Row>
        </Layout>
    )
}

export default AppBanner