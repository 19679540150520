import React from 'react'

import { Col, Layout, Row } from 'antd'

import './Pay.scss'

import visa from '../../assets/img/visa_master.png'
import mir from '../../assets/img/mir.png'
// import qiwi from '../../assets/img/qiwi.png'
// import yandex from '../../assets/img/yandex.png'
// import webmoney from '../../assets/img/webmoney.png'

const Pay = () => {
    
    return (
        <Layout className="pay">
            <Row>
                <Col md={{span: 24, offset: 0}} lg={{span: 18, offset:3}}>
                    <h2 className="pay__title">Вы также можете рассчитаться:</h2>
                    <Row type="flex" align="middle" className="pay__items">
                        <img src={visa} alt="" className="pay__icon" />
                        <img src={mir} alt="" className="pay__icon" />
                        {/* <img src={qiwi} alt="" className="pay__icon" />
                        <img src={yandex} alt="" className="pay__icon" />
                        <img src={webmoney} alt="" className="pay__icon" /> */}
                    </Row>
                </Col>
            </Row>
        </Layout>
    )
}

export default Pay