import React from 'react'

import { Col, Layout, Row } from 'antd'

import './Bonus.scss'

import appStore from '../../assets/img/appstore.png'
import googlePlay from '../../assets/img/google.png'

const Bonus = () => {
    
    return (
        <Layout className="bonuses">
            <Row>
                <Col xs={{span: 0}} md={{span: 7}} lg={{span: 9}}>
                    <div className="bonus"></div>
                </Col>
                <Col xs={{span: 24}} md={{span: 17}} lg={{span: 13}}>
                    <Row type="flex" className="bonus">
                        <div className="bonus__offer">
                            <div className="bonus__header">
                                <p className="bonus__subtitle">&nbsp;</p>
                            </div>
                            <div className="bonus__texts-icons d-flex">
                                <div className="bonus__texts">
                                    <p className="bonus__text">
                                        &nbsp;
                                    </p>
                                    <p className="bonus__text">
                                    &nbsp;
                                    </p>
                                    <p className="bonus__text">
                                    &nbsp;
                                    </p>
                                </div>
                                <div className="bonus__icons">
                                    <a href="https://apps.apple.com/ru/app/slipetsgroup-delivery/id1506195793" className="bonus__link" target="_blank"><img src={appStore} alt="" className="bonus__icon" /></a>
                                    <a href="https://play.google.com/store/apps/details?id=com.demmel.slipets" className="bonus__link" target="_blank"><img src={googlePlay} alt="" className="bonus__icon" /></a>
                                </div>
                            </div>
                        </div>
                    </Row>
                </Col>
                <Col xs={{span: 0}} md={{span: 0}} lg={{span:2}}>
                    <div className="bonus"></div>
                </Col>
            </Row>
        </Layout>
    )
}

export default Bonus