import { Col, Form, Radio, Row } from 'antd';
import React from 'react';
import InputMask from 'react-input-mask';

import '../LoginForm/LoginForm.scss';

const ForgetPasswordForm = (props) => {
	return (
		<Row>
			<Col span={24}>
				<Row type='flex' align='bottom' justify='center'>
					<div className='sign'>
						<div className='sign__menu'>
							<h3 className='sign__link_active'>Восстановление доступа</h3>
						</div>
						<Form name='loginForm'>
							<div className='sign__form'>
								<div className='sign__item'>
									<span className='sign__label'>Телефон</span>
									<span className='order-form__require'>*</span>
									<Form.Item
										name='userPhone'
										rules={[
											{
												required: true,
												message: 'Укажите свой номер телефона',
											},
											() => ({
												validator(_, value) {
													value = value
														.replace('+', '')
														.replace(/-/g, '')
														.replace(/_/g, '')
														.replace(/ /g, '')
														.trim();
													if (value.length == 11) {
														return Promise.resolve();
													}
													return Promise.reject('Некорректный номер телефона!');
												},
											}),
										]}
									>
										<InputMask
											mask='+9 999 999-99-99'
											placeholder='+7 123 456-78-90'
											className='sign__input'
											onChange={props.onUserPhoneChange}
										/>
										{/* <input type="tel" className="sign__input" placeholder="+7 123 456-78-90" onChange={props.onUserPhoneChange}/> */}
									</Form.Item>
								</div>
								<Radio.Group
									defaultValue='call'
									onChange={props.onUserCodeWayChange}
								>
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										<Radio value='call'>Получить код активации по звонку</Radio>
										<Radio value='sendsms'>Получить код активации из смс</Radio>
									</div>
								</Radio.Group>
								<div className='sign__action'>
									<button
										type='submit'
										className='cart_btn sign__button'
										onClick={props.submitForm}
									>
										Получить код
									</button>
								</div>
							</div>
						</Form>
					</div>
				</Row>
			</Col>
		</Row>
	);
};

export default ForgetPasswordForm;
