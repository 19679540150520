import { newsApi } from '../../utils/api'

import { 
	BANNER_GET_START, BANNER_GET_SUCCESS, BANNER_GET_ERROR
} from '../actions/actionTypes'

const actions = {
    getBannersStart: () => ({
        type: BANNER_GET_START
    }),
    getBanners: items => ({
        type: BANNER_GET_SUCCESS,
        banners: items
    }),
    getBannersError: (e) => ({
        type: BANNER_GET_ERROR,
        error: e
    }),
    fetchBanners: () => (dispatch) => {
        dispatch(actions.getBannersStart())
        newsApi.getBanners().then((data) => {
            dispatch(actions.getBanners(data))
        }).catch((e) => {
            dispatch(actions.getBannersError(e))
        })
    },
}

export default actions