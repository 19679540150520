import { 
	REVIEW_GET_START, REVIEW_GET_SUCCESS, REVIEW_GET_ERROR,
	REVIEW_ADD_START, REVIEW_ADD_SUCCESS, REVIEW_ADD_ERROR, REVIEW_ADD_RESET
} from '../actions/actionTypes'

const initialState = {
	loading: false,
	reviews: [],
	review: null
}

export default (state = initialState, action) => {
	switch (action.type) {
		case REVIEW_GET_START:
			return {
				...state,
				loading: true
			}
		case REVIEW_GET_SUCCESS:
			return {
				...state,
				reviews: action.reviews,
				loading: false
			}
		case REVIEW_GET_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			}
		case REVIEW_ADD_START:
			return {
				...state,
				loading: true
			}
		case REVIEW_ADD_SUCCESS:
			return {
				...state,
				review: action.review,
				loading: false
			}
		case REVIEW_ADD_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			}
		case REVIEW_ADD_RESET:
				return {
					...initialState,
				}
		default:
			return state
	}
}