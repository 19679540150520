import React, { useState, useEffect }  from "react"
import { Layout } from 'antd'
import { connect } from "react-redux"

import { testAction } from "../../redux/actions"

const { Content } = Layout

const TestPage = ({fetchTest, object}) => {

	const [filtered, setFilteredItems] = useState(object ? Array.from(object) : [])

	useEffect(() => {
        if(!object) {
            fetchTest()
        } else {
            setFilteredItems(object)
        }
    }, [object])
	
	return (
		<React.Fragment>
			<Content>
				Hello worldss
			</Content>
		</React.Fragment>
	)
}

export default connect(
    ({ object }) => object,
    testAction
)(TestPage)
