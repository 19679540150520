import React from "react"
import { Layout } from 'antd'

import { Footer } from "../../components"

import { SignUp } from '../../containers'

import "./Signup.scss"

const Signup = () => {
	return (
		<React.Fragment>
			<SignUp />
			<Layout>
				<Footer />
			</Layout>
		</React.Fragment>
	)
}

export default Signup
