import {axios}  from '../../core'

import config from './config'

export default {
    getAll: async () => {

        const auth = await axios({
            method: 'post',
            url: '/user/auth',
            data: {
                login: config.admin.login,
                password: config.admin.password
            }
        })

        if (auth.data) {

            const products = await axios({
                method: "get",
                url: "/items",
                headers: {
                    'Authorization': `Bearer ${auth.data.token}`
                }
            })

            return products.data.itemsRes
        }
    },
    getAllByCategoryId: async (_id) => {

        // const auth = await axios({
        //     method: 'post',
        //     url: '/user/auth',
        //     data: {
        //         login: config.admin.login,
        //         password: config.admin.password
        //     }
        // })

        // if (auth.data) {

        const products = await axios({
            method: "get",
            url: `/categories/${_id}/items`,
            // headers: {
            //     'Authorization': 'Bearer ' + auth.data.token
            // }
        })

        if (products.data) {
            return products.data
        }
        // }
    },
    getAllByMainCategoryId: async (_id) => {

        // const auth = await axios({
        //     method: 'post',
        //     url: '/user/auth',
        //     data: {
        //         login: config.admin.login,
        //         password: config.admin.password
        //     }
        // })

        // if (auth.data) {

        const products = await axios({
            method: "get",
            url: `/categories/${_id}/items_all`,
            // headers: {
            //     'Authorization': 'Bearer ' + auth.data.token
            // }
        })

        if (products.data) {
            return products.data
        }
        // }
    }
}