import { TEST_GET_ITEMS_START, TEST_GET_ITEMS_SUCCESS, TEST_GET_ITEMS_ERROR, TEST_SET_CURRENT } from '../actions/actionTypes'

const initialState = {
	loading: false,
	object: null
}

export default (state = initialState, action) => {
	switch (action.type) {
		case TEST_GET_ITEMS_START:
			return {
				...state,
				loading: true
			}
		case TEST_GET_ITEMS_SUCCESS:
			return {
				...state,
				object: action.object,
				loading: false
			}
		case TEST_GET_ITEMS_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			}
		default:
			return state
	}
}
