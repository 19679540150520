import React from 'react'

import newSVG from '../../assets/img/new.svg'
import groupsSVG from '../../assets/img/group.svg'
import noMushroomSVG from '../../assets/img/no_mushroom.svg'
import noPigSVG from '../../assets/img/no_pig.svg'

const FilterMealsItem = ({ title, url, type }) => {
    return (
        <a href={url} className="meal__link">
            { type === 'new' && <object type="image/svg+xml" data={newSVG} className="meal__icon" title="new"/> }
            { type === 'hit' && <i className="fas fa-star"></i> }
            { type === 'hot' && <i className="fas fa-pepper-hot"></i> }
            { type === 'vegan' && <i className="fas fa-leaf"></i> }
            { type === 'fish' && <i className="fas fa-fish"></i> }
            { type === 'meat' && <i className="fas fa-steak"></i> }
            { type === 'bird' && <i className="fas fa-turkey"></i> }
            { type === 'group' && <object type="image/svg+xml" data={groupsSVG} className="meal__icon"  title="group"/> }
            { type === 'no_mushroom' && <object type="image/svg+xml" data={noMushroomSVG} className="meal__icon"  title="no_mushroom"/> }
            { type === 'no_pig' && <object type="image/svg+xml" data={noPigSVG} className="meal__icon"  title="no_pig"/>}
            <span className="meal__text meal__text_mt">{title}</span>
        </a>
    );
}

export default FilterMealsItem;