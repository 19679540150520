import { Col, Layout, Row } from 'antd';
import React from 'react';

import { Footer } from '../../components';

import './About.scss';

const { Content } = Layout;

const About = () => {
	return (
		<React.Fragment>
			<Content className='aboutpage'>
				<Row className='aboutpage__item'>
					<Col lg={{ span: 18, offset: 3 }}>
						<h1 className='aboutpage__header'>
							Заказ еды на дом. Служба доставки вкусной еды Slipetsgroup
						</h1>
					</Col>
				</Row>
				<Row className='aboutpage__item'>
					<Col lg={{ span: 18, offset: 3 }}>
						<p className='aboutpage__text'>
							Наша служба доставки – это большая, дружная команда
							профессионалов, объединенных любовью к еде. Мы обожаем свою работу
							– закупать свежайшие продукты, разрабатывать новые позиции меню,
							изобретать фирменные блюда, готовить всю эту вкуснятину и
							доставлять вам и еду, и удовольствие!
						</p>
						<p className='aboutpage__text'>
							Мы – не ресторан с мишленовскими звездами и вековой историей, а
							молодая, дружная компания, которая и сама любит поесть, и других
							накормить. За годы нашей работы мы успели обзавестись
							многочисленными почитателями нашей кухни и полчищем постоянных
							клиентов, разделяющих нашу любовь ко вкусной еде.
						</p>
						<p className='aboutpage__text'>
							Наши основные направления на протяжении всего этого времени
							остаются прежними – это доставка пиццы, шашлыка, суши, заказ еды
							на дом или доставка обедов в офис. Но, разумеется, мы не стоим на
							месте, находясь в постоянном поиске новых вкусов и вариантов
							подачи блюд. Советуйте, предлагайте, подсказывайте – мы любим
							экспериментировать и адекватно относимся к конструктивной критике.
							Оценивать наши старания будете вы.
						</p>
						<p className='aboutpage__text'>
							Надеемся, что мы всегда будем желанными гостями в вашем доме или
							офисе!
						</p>
						<p className='aboutpage__text'>
							Мы не ждем всяких там ревизоров, а сами приглашаем вас в святая
							святых – на нашу кухню! Именно здесь рождаются наши кулинарные
							шедевры!
						</p>
						<h4 className='aboutpage__important'>ВНИМАНИЕ! ОТВЕТСТВЕННОСТЬ!</h4>
						<p className='aboutpage__text'>
							Доставка еды SlipetsGroup использует исключительно свежие продукты
							и готовит для вас сразу после подтверждения заказа. У Вас есть
							возможность изменить заказ, в течение 10 минут, но к сожалению, мы
							не сможем отменить его, если:
						</p>
						<p className='aboutpage__text'>
							1) Заказ был подтверждён и истекло время, предоставляемое для
							изменения заказа. <br />
							2) Заказ был приготовлен и упакован для вас.
						</p>
						<p className='aboutpage__text'>
							Делая заказ в SlipetsGroup, вы тратите деньги с умом! Наша
							накопительная система бонусов – прекрасный повод ни в чем себе не
							отказывать!
						</p>
						<p className='aboutpage__text'>
							Если заказ был не оплачен, мы оставляем за собой право присвоить
							долг, равный сумме не оплаченного заказа на ваш бонусный счет.
							Последующие заказы будут возможны, только, после погашения суммы
							долга.
						</p>
						<p className='aboutpage__text'>
							Все заказы, которые так и не были доставлены нашим клиентам, мы не
							отправляем третьим лицам, а утилизируем, неся убытки.
						</p>
						<p className='aboutpage__text'>
							Мы надеемся на ваше понимание и ответственное отношение к своим
							заказам.
						</p>
						<p className='aboutpage__text aboutpage__text_margin'>
							А в свою очередь, мы будем и дальше предоставлять для Вас
							качественный сервис и обслуживание.
						</p>
					</Col>
				</Row>
				<Row className='aboutpage__item'>
					<Col lg={{ span: 18, offset: 3 }}>
						<h3 className='aboutpage__contact-header'>Контакты</h3>
						<Row
							type='flex'
							justify='start'
							className='aboutpage__contacts aboutpage__contacts_margin'
						>
							{/* <Row type='flex' align='middle' className='aboutpage__location'>
								<i className='fal fa-map-marker-alt location__icon' />
								<div className='location__desc'>
									<span className='location__title'>Адрес</span>
									<span className='location__contact-text'>
										г. Симферополь, бул. Франко, 30А
									</span>
								</div>
							</Row> */}
							<Row type='flex' align='middle' className='aboutpage__location'>
								<i className='fal fa-envelope location__icon' />
								<div className='location__desc'>
									<span className='location__title'>Почта</span>
									<span className='location__contact-text'>
										slipetsgroup@mail.ru
									</span>
								</div>
							</Row>
							<Row type='flex' align='middle' className='aboutpage__location'>
								<i className='fal fa-phone-alt location__icon' />
								<div className='location__desc'>
									<span className='location__title'>Телефон</span>
									<span className='location__contact-text'>
										8-978-839-88-88
									</span>
									<br />
									<span className='location__contact-text'>
										8-978-888-88-10
									</span>
									{/* <br />
									<span className='location__contact-text'>
										8 (3652) 888-001
									</span> */}
								</div>
							</Row>
						</Row>
					</Col>
				</Row>
			</Content>
			<Layout>
				<Footer />
			</Layout>
		</React.Fragment>
	);
};

export default About;
