import React from 'react'

import { Col, Layout, Row } from 'antd';

import { MenuTypesItem } from '../../components'

import './MenuTypes.scss'

const MenuTypes = ({ items, onMenuItemClick }) => (
    
    <Layout className="filter">
        <Row>
            <Col lg={{span: 20, offset: 2}}>
                <Row type="flex" align="middle" justify="space-between" className="filter">
                    <MenuTypesItem key={-1} id={null} title="ВСЕ" url="#" onMenuItemClick={onMenuItemClick}/>
                    {items.length ? items.map((item, index) => (
                        <MenuTypesItem key={index} id={item.id} title={item.name} url="#" onMenuItemClick={onMenuItemClick}/>
                    )) : <span>&nbsp;</span>}
                </Row>
            </Col>
        </Row>
    </Layout>
);

export default MenuTypes;