import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { Redirect } from 'react-router-dom'

import moment from 'moment'

import { usersAction } from "../../redux/actions"

import { UserAccount as BaseUserAccount } from "../../components"

const Account = ({currentUser, getCurrentUser, userOrders, getUserOrders, updateUser}) => {

    const [auth, setAuth] = useState(!!localStorage.getItem('user'))
    const [user, setUser] = useState(currentUser)
    const [orders, setOrders] = useState(userOrders)
    const [totalSum, setTotalSum] = useState(0)
    const [emailIsChange, setEmailIsChange] = useState(false) 
    const [birthDateIsChange, setBirthDateIsChange] = useState(false)
    const [sex, setSex] = useState('male')
    const [email, setEmail] = useState('user@somedomain.com') 
    const [birthDate, setBirthDate] = useState('01-01-1970')
    const [emailBeforeAccept, setEmailBeforeAccept] = useState('user@somedomain.com') 
    const [birthDateBeforeAccept, setBirthDateBeforeAccept] = useState('01-01-1970')

    useEffect(() => {
        if(!currentUser) getCurrentUser()
        else {
            setUser(currentUser)
            setAuth(true)
            if(currentUser.email !== '') {
                setEmail(currentUser.email)
                setEmailBeforeAccept(currentUser.email)
                setBirthDate(moment(currentUser.birthDate).format('DD-MM-YYYY'))
                setBirthDateBeforeAccept(moment(currentUser.birthDate).format('DD-MM-YYYY'))
                setSex(currentUser.male ? "male" : "female")
                setUser(currentUser)
            }
        }
    }, [currentUser])

    useEffect(() => {
        if(!userOrders) getUserOrders()
        else {
            setOrders(userOrders)
            setTotalSum(calculateTotalSum(userOrders))
        }
    }, [userOrders])
    
    const onEmailChange = e => setEmailBeforeAccept(e.target.value)

    const onBirthDateChange = (date, dateString) => setBirthDateBeforeAccept(dateString)

    const onSexChange = e => {
        setSex(e.target.value)
        // send change birthdate
        const userData = {
            'id': currentUser._id,
            'email': email,
            'birthDate': new Date(moment(birthDate, 'DD-MM-YYYY')),
            'male': e.target.value === 'male' ? true : false
        }

        updateUser(userData)
    }
    
    const onEmailClick = () => {
        setEmailIsChange(true)
        setEmailBeforeAccept(email)
    }

    const onEmailAcceptClick = () => {
        setEmail(emailBeforeAccept)
        setEmailIsChange(false)

        // send change email
        const userData = {
            'id': currentUser._id,
            'email': emailBeforeAccept,
            'birthDate': new Date(moment(birthDate, 'DD-MM-YYYY')),
            'male': sex === 'male' ? true : false 
        }

        updateUser(userData)
    }

    const onEmailCloseClick = () => {
        setEmailIsChange(false)
    }

    const onBirthDateAcceptClick = () => {
        setBirthDate(birthDateBeforeAccept)
        setBirthDateIsChange(false)

        // send change birthdate
        const userData = {
            'id': currentUser._id,
            'email': email,
            'birthDate': new Date(moment(birthDateBeforeAccept, 'DD-MM-YYYY')),
            'male': sex === 'male' ? true : false
        }

        updateUser(userData)
    }

    const onBirthDateCloseClick = () => {
        setBirthDateIsChange(false)
    }

    const onBirthDateClick = () => {
        setBirthDateIsChange(true)
        setBirthDateBeforeAccept(birthDate)
    }

    const onPressEnterEmail = (e) => {
        if (e.key === 'Enter') {
            setEmailIsChange(false)
            setEmail(emailBeforeAccept)
        } 
    }

    const onPressEnterBirthDate = (e) => {
        if (e.key === 'Enter') {
            setEmailIsChange(false)
            setEmail(emailBeforeAccept)
        } 
    }

    const calculateTotalSum = (orderItems) => {
        let sum = 0
        orderItems.map(item => {
            sum += item.items.reduce((sumTotal, itemReduce) => sumTotal + itemReduce.sum, 0)
        })

        return sum
    }

    return (
        !auth ? 
            <Redirect to="/signin" /> : 
            <BaseUserAccount 
                emailIsChange = {emailIsChange}
                birthDateIsChange = {birthDateIsChange}
                email= {email}
                birthDate = {birthDate}
                sex={sex}
                emailBeforeAccept= {emailBeforeAccept}
                birthDateBeforeAccept = {birthDateBeforeAccept}
                currentUser = {user}
                userOrders = {orders}
                totalSum = {totalSum}
                onEmailChange = {onEmailChange}
                onBirthDateChange = {onBirthDateChange}
                onSexChange = {onSexChange}
                onEmailClick = {onEmailClick}
                onEmailAcceptClick = {onEmailAcceptClick}
                onEmailCloseClick = {onEmailCloseClick}
                onBirthDateAcceptClick = {onBirthDateAcceptClick}
                onBirthDateCloseClick = {onBirthDateCloseClick}
                onBirthDateClick = {onBirthDateClick}
                onPressEnterEmail = {onPressEnterEmail}
                onPressEnterBirthDate = {onPressEnterBirthDate}
            />
    )
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.users.currentUser,
        userOrders: state.users.userOrders 
    }
}

export default connect(mapStateToProps, usersAction)(Account)