import React from 'react'
import { Row } from 'antd'

const ReviewItem = ({userName, reviewText, reviewDate}) => {

    return (
        <Row type="flex" className="reviews__item">
            <div className="reviews__logo">
                <i className="fal fa-user-circle"/>
                <span className="reviews__logo-name">{userName}</span>
            </div>
            <div className="reviews__content">
                <span className="reviews__content-date">{reviewDate}</span>
                <p className="reviews__content-text">
                    {reviewText}
                </p>
            </div>
        </Row>
    )
}

export default ReviewItem