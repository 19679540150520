import React, { useState } from "react"
import { Form, Row, Col, Checkbox, Radio } from "antd"
import InputMask from "react-input-mask"
import { NavLink } from "react-router-dom"

import "../LoginForm/LoginForm.scss"

const SignUpForm = (props) => {
    const [orderButtonVisible, setOrderButtonVisible] = useState(true)

    const onChangeAccept = () => {
        setOrderButtonVisible(!orderButtonVisible)
    }

    return (
        <Row>
            <Col span={24}>
                <Row type="flex" align="bottom" justify="center">
                    <div className="sign sign_short">
                        <div className="sign__menu">
                            <NavLink to="/signin" className="sign__link">
                                Вход
                            </NavLink>
                            <NavLink to="/signup" className="sign__link sign__link_active">
                                Регистрация
                            </NavLink>
                        </div>
                        <Form name="loginForm">
                            <div className="sign__form">
                                <div className="sign__item">
                                    <span className="sign__label">Имя</span>
                                    <span className="order-form__require">*</span>
                                    <Form.Item
                                        name="userName"
                                        rules={[{ required: true, message: "Укажите свое имя" }]}
                                    >
                                        <input
                                            type="text"
                                            className="sign__input"
                                            placeholder="Иванов Иван"
                                            onChange={props.onUserNameChange}
                                        />
                                    </Form.Item>
                                </div>
                                <div className="sign__item">
                                    <span className="sign__label">Телефон</span>
                                    <span className="order-form__require">*</span>
                                    <Form.Item
                                        name="userPhone"
                                        rules={[
                                            { required: true, message: "Укажите свой номер телефона" },
                                            () => ({
                                                validator(_, value) {
                                                    value = value
                                                        .replace("+", "")
                                                        .replace(/-/g, "")
                                                        .replace(/_/g, "")
                                                        .replace(/ /g, "")
                                                        .trim()
                                                    if (value.length == 11) {
                                                        return Promise.resolve()
                                                    }
                                                    return Promise.reject("Некорректный номер телефона!")
                                                },
                                            }),
                                        ]}
                                    >
                                        <InputMask
                                            mask="+9 999 999-99-99"
                                            placeholder="+7 123 456-78-90"
                                            className="sign__input"
                                            onChange={props.onUserPhoneChange}
                                        />
                                        {/* <input type="tel" className="sign__input" placeholder="+7 123 456-78-90" onChange={props.onUserPhoneChange}/> */}
                                    </Form.Item>
                                </div>
                                <div className="sign__item">
                                    <span className="sign__label">E-mail</span>
                                    <Form.Item>
                                        <input
                                            type="email"
                                            className="sign__input"
                                            placeholder="user@somedomain.com"
                                            onChange={props.onUserEmailChange}
                                        />
                                    </Form.Item>
                                </div>
                                <div className="sign__item">
                                    <span className="sign__label">Пароль</span>
                                    <span className="order-form__require">*</span>
                                    <Form.Item name="userPassw" rules={[{ required: true, message: "Укажите пароль" }]}>
                                        <input
                                            type="password"
                                            className="sign__input"
                                            placeholder="*********"
                                            onChange={props.onUserPasswordChange}
                                        />
                                    </Form.Item>
                                </div>
                                <Form.Item>
                                    <span className="sign__label">Пол</span>
                                    <br />
                                    <Radio.Group defaultValue="male" onChange={props.onUserSexChange}>
                                        <Radio.Button value="male">Мужской</Radio.Button>
                                        <Radio.Button value="female">Женский</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item>
                                    <Radio.Group defaultValue="call" onChange={props.onUserCodeWayChange}>
                                        <Radio value="call">Получить код активации по звонку</Radio>
                                        <Radio value="sendSMS">Получить код активации из смс</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item>
                                    <Checkbox
                                        className="sign__checkbox"
                                        checked={orderButtonVisible}
                                        onChange={onChangeAccept}
                                    >
                                        <span className="sign__checkbox-text">
                                            Даю согласие на обработку персональных данных
                                        </span>
                                        <span className="order-form__require">*</span>
                                    </Checkbox>
                                </Form.Item>
                                <div className="sign__action">
                                    <button
                                        type="submit"
                                        className="cart_btn sign__button"
                                        onClick={props.submitForm}
                                        disabled={!orderButtonVisible || props.clicked}
                                    >
                                        Зарегистрироваться
                                    </button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </Row>
            </Col>
        </Row>
    )
}

export default SignUpForm
