import React, { useState } from 'react'

import { Row, Card, Empty, Modal } from 'antd'

import config from '../../utils/api/config'

const { Meta } = Card

const CardImg = ({src, alt, description, delivery, onCartClick}) => {

    const [visible, setVisible] = useState(false)

    const newSrc = (image) => {
        
        let newImage = image.replace('.png', '_300.png')
        newImage = newImage.replace('.jpg', '_300.jpg')

        return newImage
    }

    const onImageClick = () => {
        setVisible(!visible)
    }

    return (
        <React.Fragment>
            <Modal
                centered
                visible={visible}
                className="good__modal"
                cancelText="Закрыть"
                onCancel = {onImageClick}
                onOk = {onCartClick}
                okText="В корзину"
            >
                <Row type="flex" justify="center">
                    <img className="good__modal-img" src={`${config.api.server}/${src}`} />
                    <p className="good__modal-text">{description}</p>
                </Row>
            </Modal>
            <Row type="flex" justify="center">
                <div className="good__img-container">
                    {src === '' ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={alt}></Empty>: <img src={`${config.api.server}/${newSrc(src)}`} alt={alt} className="good__img" onClick={onImageClick} />}
                </div>
            </Row>
        </React.Fragment>
    )
}

const CardItem = ({ id, title, img, rating, delivery, description, weight, price, type, filter, onCartClick }) => {
    return (
        
        <Card
            className="good"
            cover={
                <CardImg src={img} alt={title} description={description} rating={rating} delivery={delivery} onCartClick={onCartClick}/>
            }
        >
            <Meta
                className="good__main"
                title={
                    <h3 className="good__title"><i className="fal fa-info-circle"></i>{title}</h3>
                }
                description={
                    <p className="good__text">{description}</p>
                }
            />
            <Row type="flex" justify="space-between" className="good__offer">
                <span className="good__weight">{weight}</span>
            </Row>
            <Row type="flex" justify="space-between" className="good__main-offer">
                <div className="good__price">
                    {price} <i className="fas fa-ruble-sign"></i>
                </div>
                <button href="/" className="action_btn good__order-btn" 
                    onClick={onCartClick}
                >
                    <i className="fal fa-shopping-cart"></i>
                    В корзину
                </button>
            </Row>
        </Card>
    );
}

export default CardItem