import React, { useState, useEffect }  from "react"
import { Layout } from 'antd'
import { connect } from "react-redux"

import { categoriesAction } from "../../redux/actions"

import { Footer, FilterMeals } from "../../components"
import { Cards, Sliders } from "../../containers"
import { Bonus, Pay } from "../../components"

import MenuTypes from "../../components/MenuTypes"

const { Content } = Layout

const filterMealsItems = [
	{
		type: "new",
		title: "Новинки"
	},
	{
		type: "hit",
		title: "Хиты"
	},
	{
		type: "hot",
		title: "Острое"
	},
	{
		type: "vegan",
		title: "Веган"
	},
	{
		type: "fish",
		title: "Рыба"
	},
	{
		type: "meat",
		title: "Мясо"
	},
	{
		type: "bird",
		title: "Птица"
	},
	{
		type: "group",
		title: "Наборы"
	},
	{
		type: "no_mushroom",
		title: "Без грибов"
	},
	{
		type: "no_pig",
		title: "Без свинины"
	}
]

const Home = ({fetchCategories, categories, setCurrentCategory}) => {

	const [filtered, setFilteredItems] = useState(categories ? Array.from(categories) : [])

	useEffect(() => {
        if(!categories) {
            fetchCategories()
        } else {
            setFilteredItems(categories)
        }
    }, [categories])
	
	return (
		<React.Fragment>
			<Content>
				<Layout>
					<Sliders />
				</Layout>
				<MenuTypes items={filtered} onMenuItemClick={setCurrentCategory}/>
				{/* <FilterMeals items={filterMealsItems}/> */}
				<Content>
					<Cards />
				</Content>
				{/* <Bonus /> */}
				<Pay />
			</Content>
			<Layout>
				<Footer />
			</Layout>
		</React.Fragment>
	)
}

export default connect(
    ({ categories }) => categories,
    categoriesAction
)(Home)
