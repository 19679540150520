import React, { useState, useEffect } from 'react'
import { Row, Badge } from 'antd'
import { connect } from 'react-redux'

import './Account.scss'
import { NavLink } from 'react-router-dom'
import { Logout } from '..'

const Account = (props) => {

    const onMenuClick = () => {
        props.onMenuClick(true)
    }

    return (
        <Row type="flex" className="account">
            <NavLink to="/account" className="account__sign"><i className="fal fa-user-circle"></i></NavLink>
            <span className="account__cart" onClick={onMenuClick}><i className="fal fa-shopping-cart"><Badge className="ant-bage-cart" count={props.total}/></i></span>
            <Logout />
        </Row>
    )
}

const mapStateToProps = (state) => {
    return {
        total: state.cartProducts.total
    }
}

export default connect(mapStateToProps)(Account)