import React from 'react'

import { Input } from 'antd'

import { Drawer } from '../../../components'

import './Menu.scss'

const { Search } = Input

class Menu extends React.Component {

    state = {
        visible: false
    }

    componentDidUpdate(prevProps) {
        if (prevProps.visible !== this.props.visible) {
          this.setState({
              visible: this.props.visible
          })
        }
    }

    onClose = () => {
        this.props.onMenuClick(false)
        this.setState({
            visible: false
        })
    }

    renderSubMenu = (subMenu) => {
        return subMenu.map((item, index) => {
            return (<li key={index} className="menu-item__subitem" onClick={() => this.props.onMenuItemClick(item.id, false)}>{item.name}</li>)
        })
    }

    renderMenu = () => {
        return this.props.items.map((item, index) => {
           return (
            <div key={index} className="menu-item">
                <span className="menu-item__title" onClick={() => this.props.onMenuItemClick(item.id, true)}>{item.name}</span>
                <ul className="menu-item__subitems">
                    {
                        item.items.length !== 0 ? this.renderSubMenu(item.items) : null
                    }
                </ul>
            </div> 
           ) 
        })
    }

    render() {

        return (
            <div>
                <Drawer
                    placement={this.props.placement}
                    onClose={this.onClose}
                    visible={this.state.visible}
                    mask={false}
                    width={this.props.width}
                    height={this.props.height}
                    placement={this.props.placement}
                    top={this.props.top}
                    left={this.props.left}
                    right={this.props.right}
                    bottom={this.props.bottom}
                    className="drawer__menu"
                >
                    <Search
                        placeholder="Поиск по блюдам…"
                        onSearch={this.props.onChangeSearch}
                        className=""                        
                    />
                    <div className="menu-list">
                        { this.props.items !== null ? 
                        <React.Fragment>
                            <div key={-1} className="menu-item">
                                <span className="menu-item__title" onClick={() => this.props.onMenuItemClick(null, true)}>Все товары</span>
                            </div> 
                            { this.renderMenu() }
                        </React.Fragment> : null }
                    </div>
                </Drawer>
            </div>
        )
    }
    
}

export default Menu