import React from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'

import BaseLayout from './hoc/BaseLayout/BaseLayout'

import { Home, DeliveryPage, Comment, Bonuses, News, About, Faqpage, Login, AccountPage, Signup, ForgetPasswordPage, MenuPage, TestPage } from './pages'

function App() {
  return (
    <BaseLayout>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/delivery" component={DeliveryPage} />
        <Route path="/comments" component={Comment} />
        <Route path="/bonuses" component={Bonuses} />
        <Route path="/news" component={News} />
        <Route path="/about" component={About} />
        <Route path="/faq" component={Faqpage} />
        <Route path="/signin" component={Login} />
        <Route path="/signup" component={Signup} />
        <Route path="/forget" component={ForgetPasswordPage} />
        <Route path="/account" component={AccountPage} />
        <Route path="/menu/:id" component={MenuPage} />
        <Route path="/test" component={TestPage} />
      </Switch>
    </BaseLayout>
  )
}

export default withRouter(App)
