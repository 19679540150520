import React from "react"
import { Layout, Row, Col } from 'antd'

import { Footer } from "../../components"

import "./Bonuses.scss"

const { Content } = Layout

const Bonuses = () => {
	return (
		<React.Fragment>
			<Content className="bonuspage">
				<Row className="bonuspage__item">
					<Col lg={{span: 18, offset: 3}}>
						<h1 className="bonuspage__header">Заказ еды на дом. Служба доставки вкусной еды Slipetsgroup</h1>
					</Col>
				</Row>
				<Row className="bonuspage__item">
					<Col lg={{span: 18, offset: 3}}>
						<p className="bonuspage__text">
							Мы очень признательны нашим клиентам за их постоянство и верность нашей службе доставки вкусной еды в Симферополе. Именно поэтому мы решили выразить свою благодарность с помощью подарков и приятных скидок для всех наших друзей – и приверженцев нашей кухни со стажем, и лишь недавно к нам присоединившихся.
						</p>
						<p className="bonuspage__text">
							Теперь при первом же заказе на ваш личный счет начисляются бонусы в размере 10% от суммы заказа. Бонусы накапливаются при совершении каждого заказа, курс – 1 бонус равен 1 рублю. Количество бонусов на счету вы сможете отслеживать в личном кабинете. Впоследствии накопленные бонусы вы сможете потратить на приобретение замечательных подарков из нашего каталога или оплату заказов еды.
						</p>
						<p className="bonuspage__text">
							Также у нас действует многоуровневая бонусная система
						</p>
						<p className="bonuspage__text">
							1 уровень “Новичок”, как только Вы становитесь нашим клиентом, на Ваш номер телефона оформляется виртуальный бонусный счет, на который начисляются бонусы в размере 10% стоимости заказа!
						</p>
						<p className="bonuspage__text">
							2 уровень “Любитель”, когда сумма всех Ваших заказов превысит 15 000 руб., на Ваш счет начнут начислятся бонусы в размере 15% стоимости заказа!
						</p>
						<p className="bonuspage__text">
							3 уровень “Профи”, когда сумма всех Ваших заказов превысит 25 000 руб., на Ваш счет начнут начислятся бонусы в размере 20% стоимости заказа!
						</p>
						{/* <p className="bonuspage__text">
							4 уровень “VIP”, когда сумма всех Ваших заказов превысит 50 000 руб., на Ваш счет начнут начислятся бонусы в размере 25% стоимости заказа!
						</p> */}
						<p className="bonuspage__text">
							ВАЖНО: Ваш личный бонусный счет прикреплен к вашему мобильному телефону, с которого вы делали заказ!
						</p>
						<p className="bonuspage__text">
							Делая заказ в Slipets Group, вы тратите деньги с умом! Наша накопительная система бонусов – прекрасный повод ни в чем себе не отказывать!
						</p>
						<p className="bonuspage__text bonuspage__text_margin">
							Уважаемые клиенты! При смене номера телефона на бонусном счете необходимо оформить 3 заказа с нового номера телефона на имеющийся бонусный счет, чтоб его активировать. Бонусы при этом начисляются. Использовать бонусы можно уже с четвертого заказа. Акции, бонусы и подарки не суммируются. На акционные товары бонусы не начисляются!
						</p>
					</Col>
				</Row>            
        	</Content>
			<Layout>
				<Footer />
			</Layout>
		</React.Fragment>
	)
}

export default Bonuses
