import React from "react"
import { Layout } from 'antd'

import { Footer } from "../../components"

import { Account } from '../../containers'

import "./AccountPage.scss"

const AccountPage = () => {
	return (
		<React.Fragment>
			<Account />
			<Layout>
				<Footer />
			</Layout>
		</React.Fragment>
	)
}

export default AccountPage
