import React, { useState } from 'react'
import { Menu, Row } from 'antd'

import { NavLink } from 'react-router-dom'

const { Item } = Menu

const MainNavMobile = (props) => {

    const [visible, setVisible] = useState(false)

    const onMenuClick = () => {
        props.onMenuClick(true)
    }
    
    return (
        <React.Fragment>
            <Row type="flex" className="menu_mobile-visible">
                <span onClick={onMenuClick} className="item item__link item__link_mobile" rel="noopener noreferrer">
                    <i className="fal fa-burger-soda"></i>
                    Меню
                </span>
                <span onClick={() => setVisible(!visible)} className="item item__link item__link_more" rel="noopener noreferrer">
                    <i class="fal fa-ellipsis-h"/>
                </span>
            </Row>
            <Row>
                {visible && <ul className="menu menu_mobile">
                    <li key="delivery" className="item">
                        <NavLink to="/delivery" onClick={() => setVisible(!visible)} className="item__link" rel="noopener noreferrer">
                            Доставка
                        </NavLink>
                    </li>
                    {/* <li key="response" className="item">
                        <NavLink to="/comments" onClick={() => setVisible(!visible)} className="item__link" rel="noopener noreferrer">
                            Отзывы
                        </NavLink>
                    </li> */}
                    {/* <li key="bonus" className="item">
                        <NavLink to="/bonuses" onClick={() => setVisible(!visible)} className="item__link" rel="noopener noreferrer">
                            Бонусы
                        </NavLink>
                    </li> */}
                    <li key="about" className="item">
                        <NavLink to="/about" onClick={() => setVisible(!visible)} className="item__link" rel="noopener noreferrer">
                            О нас
                        </NavLink>
                    </li>
                    {/* <li key="special" className="item">
                        <NavLink to="/faq" onClick={() => setVisible(!visible)} className="item__link" rel="noopener noreferrer">
                            ЧАВО
                        </NavLink>
                    </li> */}
                </ul>}
            </Row>
        </React.Fragment>
    )
}

export default MainNavMobile