import axios from 'axios'

export default {
    getAll: async () => {
        // const auth = await axios({
        //     method: 'post',
        //     url: '/user/auth',
        //     data: {
        //         login: config.admin.login,
        //         password: config.admin.password
        //     }
        // })

        // if (auth.data) {
            
        const categories = await axios({
            method: "get",
            url: "https://test.bnet24.com/categories",
            // url: "/categories",
            // headers: {
            //     'Authorization': `Bearer ${auth.data.token}`
            // }
        })

        if (categories.data) {
            return categories.data
        }
        // }
    }
}