import React from 'react'
import { NavLink } from 'react-router-dom'

const MenuTypesItem = ({ id, title, onMenuItemClick }) => {
    return (
        <React.Fragment>
            {
                id ? <NavLink to={`/menu/${id}`} className="filter__link">{title}</NavLink> : 
                <a href='/' className="filter__link">{title}</a>
            }
        </React.Fragment>
    );
}

export default MenuTypesItem;

