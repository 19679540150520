import React, { useState } from 'react'
import { Layout, Row, Col, Radio, DatePicker, Collapse, Spin } from 'antd'
import moment from 'moment'

import './UserAccount.scss'

import locale from 'antd/es/date-picker/locale/ru_RU'

const { Content } = Layout
const { Panel } = Collapse

const renderText = (text) => (
    <span className="order__title">{text}</span>
)

const renderSum = (text) => (
    <span className="order__title">{text}<i className="fal fa-ruble-sign"/></span>
)

const UserAccount = (props) => {

    const [numItems, setNumItems] = useState(10)
    const [loading, setLoading] = useState(false)

    const { email, birthDate, birthDateBeforeAccept, emailBeforeAccept, onPressEnterBirthDate, currentUser, sex, userOrders,
            onPressEnterEmail, onEmailClick, onBirthDateClick, onEmailAcceptClick, onEmailCloseClick, onSexChange, totalSum,
            onEmailChange, onBirthDateChange, birthDateIsChange, emailIsChange, onBirthDateAcceptClick, onBirthDateCloseClick
        } = props

    const renderItems = (items) => {
        return items && items.map((item, index) => (
            <Row type="flex" justify="space-between" className="order__item" key={index}>
                <div className="order__item-text order__item-text_name">{item.productName}</div>
                <div className="order__item-text order__item-text_price">{item.price}</div>
                <div className="order__item-text order__item-text_count">{item.count}</div>
                <div className="order__item-text order__item-text_summa">{item.sum}</div>
            </Row>
        )) 
    }

    const renderOrders = () => {
        return userOrders && userOrders.slice(0, numItems).map((item, index) => {
            let sum = item.items.reduce((sumTotal, itemReduce) => sumTotal + itemReduce.sum, 0)
            return (<Panel header={renderText(`Заказ № ${item.num} от ${moment(item.dateAdd).format('DD-MM-YYYY')}`)} key={index} extra={renderSum(`На сумму ${sum}`)}>
                {renderItems(item.items)}
            </Panel>
        )})
    }

    const onMoreBtnClick = () => {
        setLoading(true)
        setTimeout(() => {
            setNumItems(numItems+10)
            setLoading(false)
        }, 2000)
    }

    const MoreButton = (
        <button className="show-more_btn" onClick={onMoreBtnClick}>Показать еще</button>
    )

    return (
        <Content>
            <Row className="account">
                <Col lg={{span: 21, offset: 1}} xl={{span: 18, offset: 3}}>
                    <h1 className="account__header">Личный кабинет</h1>
                    <Row type="flex" justify="center" className="account__cards">
                        <Row type="flex" justify="space-between" className="account__cards account__cards_add">
                            <div className="account__info account__card">
                                <h3 className="account__title">Личная информация</h3>
                                <Row type="flex" className="account__infos" justify="space-between" align="middle">
                                    <span className="account__header account__header_info">Имя</span>
                                    <span className="account__value">{currentUser && currentUser.name}</span>
                                </Row>
                                <Row type="flex" className="account__infos" justify="space-between" align="middle">
                                    <span className="account__header account__header_info">E-mail</span>
                                    { emailIsChange ? 
                                        <Row type="flex">
                                            <input onKeyDown={onPressEnterEmail} className="account__input" onChange={onEmailChange} value={emailBeforeAccept}/>
                                            <button className="account__button account__button_accept" onClick={onEmailAcceptClick}><i className="fal fa-check"/></button>
                                            <button className="account__button account__button_close" onClick={onEmailCloseClick}><i className="fal fa-times"/></button>
                                        </Row> :
                                        <span className="account__value account__value_edited" onClick={onEmailClick}>{email}</span>
                                    }
                                </Row>
                                <Row type="flex" className="account__infos" justify="space-between" align="middle">
                                    <span className="account__header account__header_info">Телефон</span>
                                    <span className="account__value">{currentUser && currentUser.phone}</span>
                                </Row>
                                <Row type="flex" className="account__infos" justify="space-between" align="middle">
                                    <span className="account__header account__header_info">Пол</span>
                                    <span className="account__value">
                                        <Radio.Group defaultValue={sex} value={sex} onChange={onSexChange}>
                                            <Radio.Button value="male">Мужской</Radio.Button>
                                            <Radio.Button value="female">Женский</Radio.Button>
                                        </Radio.Group>
                                    </span>
                                </Row>
                                <Row type="flex" className="account__infos" justify="space-between" align="middle">
                                    <span className="account__header account__header_info">Дата рождения</span>
                                    { birthDateIsChange ? 
                                        <Row type="flex">
                                            <DatePicker defaultValue={moment(birthDateBeforeAccept, 'DD-MM-YYYY')} locale={locale} bordered={false} format="DD-MM-YYYY" onKeyDown={onPressEnterBirthDate} onChange={onBirthDateChange}/>
                                            <button className="account__button account__button_accept" onClick={onBirthDateAcceptClick}><i className="fal fa-check"/></button>
                                            <button className="account__button account__button_close" onClick={onBirthDateCloseClick}><i className="fal fa-times"/></button>
                                        </Row> :
                                        <span className="account__value account__value_edited" onClick={onBirthDateClick}>{birthDate}</span>
                                    }
                                </Row>
                            </div>
                            {/* <div className="account__bonus account__card">
                                <h3 className="account__title">Бонусный счет</h3>
                                <Row type="flex" className="account__infos" justify="space-between" align="middle">
                                    <span className="account__header account__header_info">Номер карты</span>
                                    <span className="account__value account__value_strong">{currentUser && currentUser.cardCode}</span>
                                </Row>
                                <Row type="flex" className="account__infos" justify="space-between" align="middle">
                                    <span className="account__header account__header_info">Остаток бонусов</span>
                                    <span className="account__value account__value_strong">{currentUser && currentUser.points} <i className="fal fa-ruble-sign"></i></span>
                                </Row>
                                <Row type="flex" className="account__infos" justify="space-between" align="middle">
                                    <span className="account__header account__header_info">Потраченные средства</span>
                                    <span className="account__value account__value_strong">{totalSum} <i className="fal fa-ruble-sign"></i></span>
                                </Row>
                            </div> */}
                            <div className="order__history account__card">
                                <h3 className="account__title">История заказов</h3>
                                <Row >
                                    <Collapse className="order__items">
                                        {renderOrders()}
                                    </Collapse>
                                </Row>
                                { userOrders && userOrders.length > numItems ?
                                    <Spin spinning={loading} delay={500}>
                                        {MoreButton}
                                    </Spin>
                                : null}
                            </div>
                        </Row>
                    </Row>
                </Col>
            </Row>

        </Content>
    )
}

export default UserAccount