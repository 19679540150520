import { axios }  from '../../core'

export default {
    getBanners: async () => {

        const banners = await axios({
            method: "get",
            url: "/news/banners"
        })

        if (banners.data) {
            return banners.data
        }
    },
    
}