import React from 'react'

import { Layout, Row, Col } from 'antd'
import { Contact, Cards } from '../../../components'

import { Drawer } from '../../../components'

import './Products.scss'

import logo from '../../../assets/img/logo.png'

class Cart extends React.Component {

    state = {
        visible: false
    }

    componentDidUpdate(prevProps) {
        if (prevProps.visible !== this.props.visible) {
          this.setState({
              visible: this.props.visible
          })
        }
    }

    onClose = () => {
        this.props.onMenuClick(false)
        this.setState({
            visible: false
        })
    }

    render() {

        let show = true
        if(window.innerWidth > 1024) {
            show = true
        } else {
            show = false
        }

        return (
            <div>
                <Drawer
                    placement="top"
                    closable={true}
                    onClose={this.onClose}
                    visible={this.state.visible}
                    top={this.props.top}
                    left={this.props.left}
                    right={this.props.right}
                    bottom={this.props.bottom}
                    width={this.props.width}
                    height={this.props.height}
                    className="drawer__product"
                    keyboard={true}
                    mask={true}
                >
                    <div className="cart">
                        <Layout>
                            <Col lg={{span: 24}}>
                                <Row type="flex" align="middle" justify="space-between">
                                     {show && <img src={logo} alt="" className="logo__img" onClick={this.onClose}/>}
                                    <Contact/>
                                    <a href="/" className="account__sign"><i className="fal fa-user-circle"></i></a>
                                </Row>
                            </Col>
                        </Layout>
                        
                        <Cards items={this.props.items} spanMd={24} offsetMd={0} spanLg={24} offsetLg={0} span={24} offset={0}/>
                    </div>
                </Drawer>
            </div>
        )
    }
    
}

export default Cart