import React, { useState } from 'react'
import { connect } from "react-redux"

import { Row, Popover } from 'antd'

import { cartProductsAction } from '../../../redux/actions'

import config from '../../../utils/api/config'

const CardTitle = ({name, price}) => {
    return(
        <div className="good__price">
            {`${name} - ${price}`} <i className="fas fa-ruble-sign"></i>
        </div>
    )
}

const CardImg = ({src, alt}) => {
    const newSrc = (image) => {
        
        let newImage = image.replace('.png', '_300.png')
        newImage = newImage.replace('.jpg', '_300.jpg')

        return newImage
    }

    return (
        <Row type="flex" justify="center">
            <img src={`${config.api.server}/${newSrc(src)}`} alt={alt} className="good__img" />
        </Row>
    )
}

const Card = ({ id, title, img, price, count, fetchCartProducts, setFilteredItems }) => {
    const [countCard, setCountCard] = useState(count)

    const changeCountOnCart = (_id, count) => {
        let cartProducts = localStorage.getItem('products')
        let cartProduct = null

        if(cartProducts) {
            let cartProductItems = JSON.parse(cartProducts)
            cartProduct = cartProductItems.filter((item) => item.id === _id)
            if (cartProduct.length !== 0) {
                cartProductItems.forEach(element => {
                    if (element.id === _id) element.count = count
                })
            }
            localStorage.setItem('products', JSON.stringify(cartProductItems))
            fetchCartProducts()
        }
    }

    const onSubButtonClick = _id => {
        let countTmp = countCard
        countTmp -= 1

        if(countTmp > 0) {
            changeCountOnCart(_id, countTmp)
            setCountCard(countTmp)
        }
    }

    const onAddButtonClick = _id => {
        let countTmp = countCard
        countTmp += 1

        changeCountOnCart(_id, countTmp)
        setCountCard(countTmp)
    }

    const onDeleteButtonClick = _id => {
        let cartProducts = localStorage.getItem('products')
        let cartProduct = null
        let products = []

        if(cartProducts) {
            let cartProductItems = JSON.parse(cartProducts)
            cartProduct = cartProductItems.filter((item) => item.id === _id)

            if(cartProducts.length === 1) {
                localStorage.removeItem('products')
            }else if (cartProduct.length !== 0) {
                products = [...cartProductItems]
                cartProductItems.map((element, index) => {
                    if (element.id === _id) products.splice(index, 1)
                })
                localStorage.setItem('products', JSON.stringify(products))
            }
            fetchCartProducts()
            setFilteredItems([])
        } 
    }

    return (    
        <div>
            <CardImg alt={title} src={img}/>
            <Row type="flex" justify="center" className="good__offer cart__offer">
                <button href="/" className="action_btn cart__count-btn cart__count-btn_sub" onClick={() => onSubButtonClick(id)}>-</button>
                <span className="good__price">{countCard}</span>
                <button href="/" className="action_btn cart__count-btn cart__count-btn_add" onClick={() => onAddButtonClick(id)}>+</button>
            </Row>
            <Row type="flex" justify="space-between" className="good__main-offer">
                <div className="good__price">
                    {price * count} <i className="fas fa-ruble-sign"></i>
                </div>
                <button href="/" className="action_btn good__order-btn" onClick={() => onDeleteButtonClick(id)}>
                    Удалить
                </button>
            </Row>
        </div>
    )
}

const CartItem = ({id, name, count, price, summa, img, fetchCartProducts, setFilteredItems}) => {
    return (
        <Row className="cart__item" type="flex" align="middle" justify="space-between">
            <Popover placement="rightTop" content={<Card id={id} title={name} img={img} price={price} count={count} fetchCartProducts={fetchCartProducts} setFilteredItems={setFilteredItems}/>} title={<CardTitle name={name} price={price}/>}>
                <div className="cart__product">{count} x {name}</div>
            </Popover>
            <div className="cart__price">
                {summa} <i className="fas fa-ruble-sign"></i>
            </div>    
        </Row>            
    )
}

export default connect(null,cartProductsAction)(CartItem)