import React from 'react'

import './Logo.scss'
import { NavLink } from 'react-router-dom'

const Logo = ({ src }) => {
    
    return (
        <NavLink to="/" className="logo">
            <img src={src} alt="" className="logo__img"/>
        </NavLink>
    )
}

export default Logo