import React from "react"
import { Layout, Row, Col, Collapse } from 'antd'
import { NavLink } from 'react-router-dom'

import { Footer } from "../../components"

import "./Faqpage.scss"

const { Content } = Layout
const { Panel } = Collapse

const renderText = (text) => (
    <span className="faqpage__title">{text}</span>
)

const Faqpage = () => {
	return (
		<React.Fragment>
			<Content className="faqpage">
				<Row className="faqpage__item">
					<Col lg={{span: 18, offset: 3}}>
						<h1 className="faqpage__header">Часто Задаваемые Вопросы</h1>
					</Col>
				</Row>
				<Row className="faqpage__item faqpage__item_margin">
					<Col lg={{span: 18, offset: 3}}>
						<Collapse className="faqpage__items">
							<Panel header={renderText("Каким может быть минимальный заказ?")} key="1">
								<p className="faqpage__text">
									Сумма минимального заказа не ограничена. Но Вы должны обратить внимание, что бесплатная доставка по городу осуществляется при сумме заказа от 500 рублей, а пригорода - от 600 рублей. Если сумма заказа меньше указанных сумм, то сумма доставки по городу будет составлять 100 рублей, а в пригород - 150 рублей.
								</p>
							</Panel>
							<Panel header={renderText("Возможно ли оформление заказа без предварительной регистрации?")} key="2">
								<p className="faqpage__text">
									Вы можете специально не проходить регистрацию в системе. При первом оформлении заказа система автоматически зарегистрирует Вас.
								</p>
							</Panel>
							<Panel header={renderText("Зачем регистрироваться на сайте?")} key="3">
								<p className="faqpage__text">
									При регистрации в системе создается учетная запись, в которой фиксируются все Ваши заказы. В свою очередь на заказы начисляются бонусы, с помощью которых Вы можете совершать покупки!
								</p>
							</Panel>
							<Panel header={renderText("Если меня зарегистрировала система автоматически при оформлении заказа, где взять пароль для входа в личный кабинет?")} key="4">
								<p className="faqpage__text">
									Если Вы совершили первый заказ и система автоматически зарегистрировала Вас. То для получения нового пароля, Вам необходимо пройти в раздел авторизации <NavLink to="/account" className="account__sign" style={{display: "inline-block"}}><i className="fal fa-user-circle"></i></NavLink> и нажать <NavLink to="/forget" className="sign__forget">Забыли пароль?</NavLink>
								</p>
							</Panel>
						</Collapse>
					</Col>
				</Row> 
			</Content>
			<Layout>
				<Footer />
			</Layout>
		</React.Fragment>
	)
}

export default Faqpage
