import { CATEGORIES_GET_ITEMS_START, CATEGORIES_GET_ITEMS_SUCCESS, CATEGORIES_GET_ITEMS_ERROR, CATEGORIES_SET_CURRENT } from '../actions/actionTypes'

const initialState = {
	loading: false,
	categories: null,
	currentCategory: null,
	mainCategory: false
}

export default (state = initialState, action) => {
	switch (action.type) {
		case CATEGORIES_GET_ITEMS_START:
			return {
				...state,
				loading: true
			}
		case CATEGORIES_GET_ITEMS_SUCCESS:
			return {
				...state,
				categories: action.categories,
				loading: false
			}
		case CATEGORIES_GET_ITEMS_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			}
		case CATEGORIES_SET_CURRENT:
			return {
				...state,
				currentCategory: action.currentCategory,
				mainCategory: action.mainCategory
			}
		default:
			return state
	}
}
