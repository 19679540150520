import { axios }  from '../../core'

import config from './config'

export default {
    getAll: async () => {

        // const auth = await axios({
        //     method: 'post',
        //     url: '/user/auth',
        //     data: {
        //         login: config.admin.login,
        //         password: config.admin.password
        //     }
        // })

        // if (auth.data) {

        const reviews = await axios({
            method: "get",
            url: "/reviews",
            // headers: {
            //     'Authorization': `Bearer ${auth.data.token}`
            // }
        })

        if (reviews.data) {
            return reviews.data
        }
    },

    addReviewApi: async (reviewData) => {

        const auth = await axios({
            method: 'post',
            url: '/user/auth',
            data: {
                login: config.admin.login,
                password: config.admin.password
            }
        })

        if (auth.data) {
            try {
                const review = await axios({
                    method: "post",
                    url: "/review",
                    data: {
                        'number': reviewData.userPhone,
                        'name': reviewData.userName,
                        'email': reviewData.userEmail,
                        'text': reviewData.reviewText
                    },
                    headers: {
                        'Authorization': `Bearer ${auth.data.token}`
                    }
                })

                return review.data

            } catch (e) {
                if (e.response) {
                    console.log(e.response)
                } else {
    
                }
            }
        }
    },
    
}