import React, { useState, useEffect }  from "react"
import { Layout } from 'antd'
import { connect } from "react-redux"
import { useParams } from "react-router-dom"

import { categoriesAction, productsAction } from "../../redux/actions"

import { Footer, Cards as BaseCards } from "../../components"
import { bindActionCreators } from 'redux'
import { Sliders } from "../../containers"

import MenuTypes from "../../components/MenuTypes"

const { Content } = Layout

const Menu = ({ fetchCategories, categories, cartVisible, setCurrentCategory, fetchProductsByCategory, fetchProducts, products, currentCategory }) => {

	const [filtered, setFilteredItems] = useState(categories ? Array.from(categories) : [])
	const [filteredProducts, setFilteredProductsItems] = useState(products ? Array.from(products) : [])

	const { id } = useParams()

	useEffect(() => {
        if(!categories) {
            fetchCategories()
        } else {
            setFilteredItems(categories)
        }
	}, [categories])

	useEffect(() => {
        if(!products) {
            fetchProductsByCategory(id, true)
        } else {
            setFilteredProductsItems(products)
        }
	}, [products])
	
	useEffect(() => {
		if(!cartVisible) {
			if(currentCategory != id) {
				setCurrentCategory(id, true)
			} else {
				fetchProductsByCategory(currentCategory, true)
			}
			
			if(!id) {
				fetchProducts()
			}
		} 
    }, [id, currentCategory])
	
	return (
		<React.Fragment>
			<Content>
				<Layout>
					<Sliders />
				</Layout>
				<MenuTypes items={filtered} onMenuItemClick={setCurrentCategory}/>
				<Content>
					<BaseCards items={filteredProducts} />
				</Content>
			</Content>
			<Layout>
				<Footer />
			</Layout>
		</React.Fragment>
	)
}

const mapStateToProps = (state) => {
    return {
		categories: state.categories.categories,
		currentCategory: state.categories.currentCategory,
		products: state.products.products,
		cartVisible: state.cartProducts.visible, 
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCategories: bindActionCreators(categoriesAction.fetchCategories, dispatch),
		setCurrentCategory: bindActionCreators(categoriesAction.setCurrentCategory, dispatch),
		fetchProducts: bindActionCreators(productsAction.fetchProducts, dispatch),
        fetchProductsByCategory: bindActionCreators(productsAction.fetchProductsByCategory, dispatch)
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(Menu)
