import React from 'react'

import './Slogan.scss'

const Slogan = () => {
    
    return (
        <h1 className="slogan">Делаем вкусно с 2014-го</h1>
    )
}

export default Slogan