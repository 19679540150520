import { categoriesApi } from '../../utils/api'
import { CATEGORIES_GET_ITEMS_START, CATEGORIES_GET_ITEMS_SUCCESS, CATEGORIES_GET_ITEMS_ERROR, CATEGORIES_SET_CURRENT } from './actionTypes'

const actions = {
    getCategoriesStart: () => ({
        type: CATEGORIES_GET_ITEMS_START
    }),
    getCategories: items => ({
        type: CATEGORIES_GET_ITEMS_SUCCESS,
        categories: items
    }),
    getCategoriesError: (e) => ({
        type: CATEGORIES_GET_ITEMS_ERROR,
        error: e
    }),
    setCurrentCategory: (id, main) => ({
        type: CATEGORIES_SET_CURRENT,
        currentCategory: id,
        mainCategory: main

    }),
    fetchCategories: () => (dispatch) => {
        dispatch(actions.getCategoriesStart())
        categoriesApi.getAll().then((data) => {
            data.sort((item1, item2) => {
                const pos1 = item1.position
                const pos2 = item2.position

                if (pos1 < pos2) return -1
                if (pos1 > pos2) return 1

                return 0
            })
            dispatch(actions.getCategories(data))
        }).catch((e) => {
            dispatch(actions.getCategoriesError(e))
        })
    }
}

export default actions