import { cartProductsApi } from '../../utils/api'

import { 
    PRODUCTS_CART_GET_ITEMS_START, PRODUCTS_CART_GET_ITEMS_SUCCESS, PRODUCTS_CART_GET_ITEMS_ERROR, 
    CART_ORDER_START, CART_ORDER_SUCCESS, CART_ORDER_ERROR, CART_SET_VISIBLE
} from './actionTypes'

const actions = {
    setCartVisible: visible => ({
        type: CART_SET_VISIBLE,
        visible: visible
    }),
    getCartProductsStart: () => ({
        type: PRODUCTS_CART_GET_ITEMS_START
    }),
    getCartProducts: (items, total, summa) => ({
        type: PRODUCTS_CART_GET_ITEMS_SUCCESS,
        cartProducts: items,
        total: total,
        summa: summa
    }),
    getCartProductsError: (e) => ({
        type: PRODUCTS_CART_GET_ITEMS_ERROR,
        error: e
    }),
    setOrderStart: () => ({
        type: CART_ORDER_START
    }),
    setOrderSuccess: (order) => ({
        type: CART_ORDER_SUCCESS,
        order: order,
        cartProducts: [],
        total: 0,
        summa: 0
    }),
    setOrderError: (e) => ({
        type: CART_ORDER_ERROR,
        error: e
    }),
    setOrder: (dataOrder) => (dispatch) => {
        dispatch(actions.setOrderStart())
        cartProductsApi.setOrder(dataOrder).then((data) => {
            dispatch(actions.setOrderSuccess(data.order))
        }).catch((e) => {
            dispatch(actions.setOrderError(e))
        })
    },
    fetchCartProducts: () => (dispatch) => {
        dispatch(actions.getCartProductsStart())
        cartProductsApi.getAll().then((data) => {
            dispatch(actions.getCartProducts(data.data, data.total, data.summa))
        }).catch((e) => {
            dispatch(actions.getCartProductsError(e))
        })
    }
}

export default actions