import React from "react"
import { Layout } from 'antd'

import { Footer, LoginForm } from "../../components"

import { SignIn } from '../../containers'

import "./Login.scss"

const Login = () => {
	return (
		<React.Fragment>
			<SignIn />
			<Layout>
				<Footer />
			</Layout>
		</React.Fragment>
	)
}

export default Login
