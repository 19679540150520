import React, { Component } from 'react'
import { connect } from "react-redux"
import { bindActionCreators } from 'redux'

import { Row, Col, Layout, BackTop } from 'antd'
import {isMobile} from 'react-device-detect'

import { YMInitializer } from 'react-yandex-metrika'

import { Header, MainNav, MainNavMobile, Order, AppBanner } from "../../components"
import { usersAction } from "../../redux/actions"

import { OrderMobile } from "../../containers"

class BaseLayout extends Component {
    state = {
        visible: false
    }

    componentDidUpdate(prevProps) {
        if(!this.props.currentUser) this.props.getCurrentUser()
    }

    onMenuClick = (visibleCart) => {
        this.setState({
            visible: visibleCart
        })
    }

    render() {
        const styleBackTop = {
            height: 40,
            width: 40,
            lineHeight: '40px',
            borderRadius: 4,
            backgroundColor: '#621D0E',
            color: '#fff',
            textAlign: 'center',
            fontSize: 16,
        }

        let menuWidth = 263
        let menuHeight = '100%'
        let menuPlacement = 'left'

        let cartWidth = 330
        let cartPlacement = 'right'

        let menuTop=0
        let menuLeft=0
        let menuRight='unset'
        let menuBottom='unset'
        
        let cartHeight='100%'
        let cartTop=0
        let cartLeft='unset'
        let cartRight=0
        let cartBottom='unset'
        
        let prodWidth=200
        let prodHeight='100%'
        let prodTop=0
        let prodLeft='unset'
        let prodRight='unset'
        let prodBottom='unset'
        let prodPlacement='unset'

        let height = '100%'
        let width = '100%'
        let top = 0
        let left = 0
        let right = 'unset'
        let bottom = 'unset'
        let placement = 'top'
        
        let mobileOrder = false

        if(window.innerWidth > 1024) {
            menuWidth = 263
            cartWidth = 330
            prodWidth = window.innerWidth - (menuWidth + cartWidth) - 9
            prodLeft = menuWidth + 5
        } else if(window.innerWidth <= 768){
            menuWidth = 160
            cartWidth = 230
            prodWidth = window.innerWidth - (menuWidth + cartWidth) - 9
            prodLeft = menuWidth + 3
        } else {
            menuWidth = 170
            cartWidth = 260
            prodWidth = window.innerWidth - (menuWidth + cartWidth) - 9
            prodLeft = menuWidth + 5
        }

        if(window.innerWidth <= 680) {
            height = '100%'
            width = '100%'
            top = 0
            left = 0
            placement = 'top'
            mobileOrder = true
        } else {
            menuPlacement = 'left'
            cartPlacement = 'right'
        }

        return (
            <div className="App">
                {!mobileOrder ? 
                    <Order 
                        menuWidth= { (typeof menuWidth) === 'number' ? `${menuWidth}px` : menuWidth}
                        menuHeight={ (typeof menuHeight) === 'number' ? `${menuHeight}px` : menuHeight}
                        menuTop={ (typeof menuTop) === 'number' ? `${menuTop}px` : menuTop}
                        menuLeft={ (typeof menuLeft) === 'number' ? `${menuLeft}px` : menuLeft}
                        menuRight={ (typeof menuRight) === 'number' ? `${menuRight}px`: menuRight}
                        menuBottom={ (typeof menuBottom) === 'number' ? `${menuBottom}px` : menuBottom}
                        menuPlacement={menuPlacement}
                        cartWidth={ (typeof cartWidth) === 'number' ? `${cartWidth}px` : cartWidth}
                        cartHeight={ (typeof cartHeight) === 'number' ? `${cartHeight}px` : cartHeight}
                        cartTop={ (typeof cartTop) === 'number' ? `${cartTop}px` : cartTop}
                        cartLeft={ (typeof cartLeft) === 'number' ? `${cartLeft}px` : cartLeft}
                        cartRight={ (typeof cartRight) === 'number' ? `${cartRight}px` : cartRight}
                        cartBottom={ (typeof cartBottom) === 'number' ? `${cartBottom}px` : cartBottom}
                        cartPlacement={cartPlacement}
                        prodWidth={ (typeof prodWidth) === 'number' ? `${prodWidth}px` : prodWidth}
                        prodHeight={ (typeof prodHeight) === 'number' ? `${prodHeight}px` : prodHeight}
                        prodTop={ (typeof prodTop) === 'number' ? `${prodTop}px` : prodTop}
                        prodLeft={ (typeof prodLeft) === 'number' ? `${prodLeft}px` : prodLeft}
                        prodRight={ (typeof prodRight) === 'number' ? `${prodRight}px` : prodRight}
                        prodBottom={ (typeof prodBottom) === 'number' ? `${prodBottom}px` : prodBottom}
                        prodPlacement={prodPlacement}
                        visible={this.state.visible}
                        onMenuClick={this.onMenuClick}
                    /> :
                    <OrderMobile 
                        width = { (typeof width) === 'number' ? `${width}px` : width}
                        height ={ (typeof height) === 'number' ? `${height}px` : height}
                        top ={ (typeof top) === 'number' ? `${top}px` : top}
                        left ={ (typeof left) === 'number' ? `${left}px` : left}
                        right ={ (typeof right) === 'number' ? `${right}px`: right}
                        bottom = { (typeof bottom) === 'number' ? `${bottom}px` : bottom}
                        placement = {placement}
                        visible={this.state.visible}
                        onMenuClick={this.onMenuClick}
                    />}

                <Layout className="header-fixed">
                    {isMobile && <AppBanner />}
                    <Header onMenuClick={this.onMenuClick}/>
                    <YMInitializer accounts={[61406788]} />
                    <Layout className="top-menu">
                        <Row>
                            <Col xs={{span: 20, offset: 0}}>
                            {!mobileOrder ? 
                                <MainNav onMenuClick={this.onMenuClick}/> :
                                <MainNavMobile onMenuClick={this.onMenuClick}/> 
                            }
                            </Col>
                        </Row>
                    </Layout>
                </Layout>
                <BackTop>
                    <div style={styleBackTop}><i className="fal fa-arrow-up"></i></div>
                </BackTop>
                <main className="main-content">
                    {this.props.children}
                </main>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.users.currentUser
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getCurrentUser: bindActionCreators(usersAction.getCurrentUser, dispatch)
    }
  }

export default connect(mapStateToProps,mapDispatchToProps)(BaseLayout)