import React from 'react'
import InputMask from "react-input-mask"

import { Row, Select, Checkbox, TimePicker, Radio, Input, Form, Slider } from 'antd'

import locale from 'antd/es/date-picker/locale/ru_RU'

import { Drawer } from '../../../components'

import './Cart.scss'

const { TextArea } = Input

const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
    borderColor: '#621D0E',

}

class Cart extends React.Component {

    state = {
        visible: false,
        orderButtonVisible: true
    }

    componentDidUpdate(prevProps) {
        if (prevProps.visible !== this.props.visible) {
          this.setState({
              visible: this.props.visible
          })
        }
    }

    onClose = () => {
        this.props.onMenuClick(false)
        this.setState({
            visible: false
        })
    }

    onChangeAccept = () => {
        this.setState({
            orderButtonVisible: !this.state.orderButtonVisible
        })
    }

    render() {

        const { 
            city, street, house, appartment, porch, floor, customerName, orderForm, bonusPaymentDisabled, sumCard,
            customerPhone, foodDevice, orderTime, paymentType, change, comment, total, bonusErrorMessage, userPoints
        } = this.props

        const { orderButtonVisible } = this.state

        let orderBtnDisabled = !orderButtonVisible

        if (bonusErrorMessage) orderBtnDisabled = true

        return (
            <div>
                <Drawer
                    placement={this.props.placement}
                    top={this.props.top}
                    left={this.props.left}
                    right={this.props.right}
                    bottom={this.props.bottom}
                    width={this.props.width}
                    height={this.props.height}
                    closable={false}
                    onClose={this.onClose}
                    visible={this.state.visible}
                    mask={false}
                >
                    <main>
                        {this.props.children}
                    </main>
                    {(total !== 0) &&
                        <Form name="orderForm" onFinish={this.props.submitForm} initialValues={{'city': city, 'foodDevice': foodDevice, 'paymentType': paymentType, 'customerName': customerName, 'customerPhone': customerPhone}}>
                            <div className="order-form">
                                <div className="order-addr">
                                    <h5 className="order-form__title">Адрес доставки</h5>
                                    <div className="order-form__item display_none">
                                        <span className="order-form__label">Город</span>
                                        <Form.Item name="city">
                                            <input type="text" className="order-form__input" placeholder="Симферополь" disabled />
                                        </Form.Item>
                                    </div>
                                    <div className="order-form__item">
                                        <span className="order-form__label">Улица</span><span className="order-form__require">*</span>
                                        <Form.Item 
                                            name="street"
                                            rules={[{ required: true, message: 'Укажите улицу' }]}
                                        >
                                            <input type="text" className="order-form__input" placeholder="Пушкина" value={street || ''} onChange={this.props.onStreetChange}/>
                                        </Form.Item>
                                    </div>
                                    <Row type="flex" align="middle" justify="space-between">
                                        <div className="order-form__item">
                                            <span className="order-form__label">Дом</span><span className="order-form__require">*</span>
                                            <Form.Item 
                                                name="house"
                                                rules={[{ required: true, message: 'Укажите номер дома' }]}
                                            >
                                                <input type="text" className="order-form__input order-form__input_short" placeholder="1" onChange={this.props.onHouseChange} value={house || ''}/>
                                            </Form.Item>
                                        </div>
                                        <div className="order-form__item">
                                            <span className="order-form__label">Квартира</span><span className="order-form__require">&nbsp;</span>
                                            <Form.Item name="appartment">
                                                <input type="number" className="order-form__input order-form__input_short" value={appartment || ''} placeholder="1" onChange={this.props.onAppartmentChange}/>
                                            </Form.Item>
                                        </div>
                                    </Row>
                                    <Row type="flex" align="middle" justify="space-between">
                                        <div className="order-form__item">
                                            <span className="order-form__label">Подъезд</span>
                                            <Form.Item name="porch">
                                                <input type="number" className="order-form__input order-form__input_short" value={porch || ''} placeholder="1" onChange={this.props.onPorchChange}/>
                                            </Form.Item>
                                        </div>
                                        <div className="order-form__item">
                                            <span className="order-form__label">Этаж</span>
                                            <Form.Item name="floor">
                                                <input type="number" className="order-form__input order-form__input_short" value={floor || ''} placeholder="1" onChange={this.props.onFloorChange}/>
                                            </Form.Item>
                                        </div>
                                    </Row>
                                </div>
                                <div className="order-contact">
                                    <h5 className="order-form__title">Контактные данные</h5>
                                    <div className="order-form__item">
                                        <span className="order-form__label">Имя</span><span className="order-form__require">*</span>
                                        <Form.Item 
                                            name="customerName"
                                            rules={[{ required: true, message: 'Укажите свое имя' }]}
                                            value={customerName}
                                        >
                                            <input type="text" className="order-form__input" placeholder="Иван" defaultValue={customerName} value={customerName} onChange={this.props.onCustomerNameChange}/>
                                        </Form.Item>
                                    </div>
                                    <div className="order-form__item">
                                        <span className="order-form__label">Телефон</span><span className="order-form__require">*</span>
                                        <Form.Item 
                                            name="customerPhone"
                                            rules={[
                                                { required: true, message: 'Укажите свой номер телефона' },
                                                () => ({
                                                    validator(_, value) {
                                                        value = value.replace('+','').replace(/-/g, '').replace(/_/g,'').replace(/ /g,'').trim()
                                                        if (value.length == 11) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject('Некорректный номер телефона!');
                                                    },
                                                  })
                                            ]}
                                            value={customerPhone}
                                        >
                                            <InputMask mask="+9 999 999-99-99" placeholder="+7 123 456-78-90" className="order-form__input" defaultValue={customerPhone} value={customerPhone} onChange={this.props.onCustomerPhoneChange}/>
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="order-food-dev">
                                    <h5 className="order-form__title">Приборы</h5>
                                    <div className="order-form__item">
                                        <Form.Item name="foodDevice">
                                            <Select className="order-form__select" onChange={this.props.onFoodDeviceChange}>
                                                <Select.Option value="none">Не нужны</Select.Option>
                                                <Select.Option value="1">На 1 персону</Select.Option>
                                                <Select.Option value="2">На 2 персоны</Select.Option>
                                                <Select.Option value="3">На 3 персоны</Select.Option>
                                                <Select.Option value="4">На 4 персоны</Select.Option>
                                                <Select.Option value="5">На 5 персон</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="order-time">
                                    <Form.Item name="orderTimeNow">
                                        <Checkbox className="order-form__checkbox" checked={orderTime === 'now' ? true : false} onChange={this.props.onNowOrderTimeChange}>
                                            <span className="order-form__checkbox-text">На ближайшее время</span>
                                        </Checkbox>
                                    </Form.Item>
                                    {orderTime !== 'now' ? 
                                        <React.Fragment>
                                            <h5 className="order-form__title">Доставка ко времени</h5>
                                            <div className="order-form__item">
                                                <Form.Item name="orderTimeTo">
                                                    <TimePicker locale={locale} bordered={false} className="order-form__time" format="HH:mm" placeholder="00:00" onChange={this.props.onOrderTimeChange}/>
                                                </Form.Item>
                                            </div>
                                        </React.Fragment>
                                    : null}
                                </div>
                                <div className="order-pay">
                                    <h5 className="order-form__title">Оплата</h5>
                                    
                                    {bonusErrorMessage && <span className="order-form__error">{bonusErrorMessage}</span>}
                                    <Form.Item name="paymentType">
                                        <Radio.Group value={paymentType} onChange={this.props.onPaymentTypeChange}>
                                            <Radio style={radioStyle} value="cash">
                                                Наличными курьеру
                                            </Radio>
                                            {/* <Radio style={radioStyle} value="bonus" disabled={!bonusPaymentDisabled}>
                                                Оплата бонусами
                                            </Radio> */}
                                            <Radio style={radioStyle} value="card_on_delivery">
                                                Оплата картой курьеру
                                            </Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                    {/* {(userPoints && (paymentType === 'cash' || paymentType === 'card_on_delivery' )) && bonusPaymentDisabled ? 
                                    <> 
                                    <Row type="flex" className="order-form__add-marks" justify="space-between" align="middle">
                                        <span>Частичная оплата бонусами</span>
                                        <input type="number" min={0} max={userPoints} className="order-form__input order-form__input_short1" value={sumCard} onChange={this.props.onInputSumCardChange}/>
                                    </Row>
                                    <Row type="flex" justify="space-around">
                                        <span className="order-form__marks">{0}</span>
                                        <Slider
                                            min={0}
                                            max={userPoints}
                                            onChange={this.props.onSumCardChange}
                                            value={sumCard}
                                            className="order-form__slider"
                                        />
                                        <span className="order-form__marks">{userPoints}</span>
                                    </Row></> : null} */}
                                    {paymentType === 'cash' ? <div className="order-form__item">
                                        <Form.Item name="change">
                                            <input type="number" className="order-form__input" placeholder="Сдача с…" value={change || ''} onChange={this.props.onCashChange}/>
                                        </Form.Item>
                                    </div> : null}
                                </div>
                                <div className="order-pay">
                                    <h5 className="order-form__title">Комментарий</h5>
                                    <Form.Item name="comment">
                                        <TextArea className="order-form__textarea" rows={4} placeholder="Ваш комментарий" value={comment || ''} onChange={this.props.onCommentChange}/>
                                    </Form.Item>
                                </div>
                                <p className="order-form__text">
                                    Если у вас имеются противопоказания к каким-либо ингредиентам, укажите это в примечании к заказу и мы уберем их из состава Вашего заказа.
                                </p>
                                <Form.Item name="accept">
                                    <Checkbox className="order-form__checkbox" checked={orderButtonVisible} onChange={this.onChangeAccept}>
                                        <span className="order-form__checkbox-text">Даю согласие на обработку персональных данных</span><span className="order-form__require">*</span>
                                    </Checkbox>
                                </Form.Item>
                                <div className="order-form__action">
                                    <button className='action_btn order-form__button' disabled={orderBtnDisabled}>Заказать</button>
                                </div>
                            </div>
                        </Form>}
                </Drawer>
            </div>
        )
    }
    
}

export default Cart